import React, { useState, useEffect } from 'react';
import '../../scss/style.scss'

import {
  CButton,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CFormTextarea,
  CAlert,
  CForm,
  CCol,
  CRow,
  CModal,
  CModalFooter,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CFormInput,
  CFormLabel,
} from '@coreui/react'

import { AppSidebar } from 'src/components/Administrateur'
import axiosInstance from 'src/hooks/axios';

export default function DocumentationBenevole() {

  const [modalSupprimer, setModalSupprimer] = useState(false);
  const [modalCourriel, setModalCourriel] = useState(false);

  const [alert, setAlert] = useState(false);
  const [alertCreer, setAlertCreer] = useState(false);
  const [nouvelleDescription, setNouvelleDescription] = useState('');
  const [nouveauTitre, setNouveauTitre] = useState('');
  const [nouveauLien, setNouveauLien] = useState('');
  const [documentation, setDocumentation] = useState([]);
  const [idDocumentationSelectionner, setIdDocumentationSelectionner] = useState();

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get('/documentationBenevole');
      setDocumentation(res.data);
    }

    fetch();

  }, []);

  const changerDescription = (id, nouvelleDescription) => {
    setDocumentation(prevDocumentation => {
      return prevDocumentation.map(documentations => {
        if (documentations.id === id) {
          return { ...documentations, description: nouvelleDescription }
        } else {
          return documentations
        }
      })
    })
  }

  const changerTitre = (id, nouveauTitre) => {
    setDocumentation(prevDocumentation => {
      return prevDocumentation.map(documentations => {
        if (documentations.id === id) {
          return { ...documentations, titre: nouveauTitre }
        } else {
          return documentations
        }
      })
    })
  }

  const changerLien = (id, nouveauLien) => {
    setDocumentation(prevDocumentation => {
      return prevDocumentation.map(documentations => {
        if (documentations.id === id) {
          return { ...documentations, lien: nouveauLien }
        } else {
          return documentations
        }
      })
    })
  }

  const changerDocumentation = async (e) => {
    e.preventDefault();

    if (idDocumentationSelectionner === null) {
      return;
    }

    const documentationSelectionner = documentation.find((item) => item.id === idDocumentationSelectionner);

    if (!idDocumentationSelectionner) {
      return;
    }

    const { titre, description, lien } = documentationSelectionner;

    await axiosInstance.put(`/modifierDocumentation/${idDocumentationSelectionner}`, { titre, description, lien })
    setAlert(true);
  }

  const creerDocumentation = (e) => {
    e.preventDefault();
    axiosInstance.post(`/creerDocumentationBenevole`, { titre: nouveauTitre, description: nouvelleDescription, lien: nouveauLien });
    setAlertCreer(true);
  }

  const supprimer = (id) => {
    axiosInstance.delete(`/supprimerDocumentation/${id}`).then(() => { window.location.reload() });
  }

  const fermerModal = async () => {
    setModalCourriel(false);
    setAlertCreer(false);
    window.location.reload();
  }

  const alertModifier = async (id) => {
    setAlert(false);
    setIdDocumentationSelectionner(id);
  }

  return (
    <>
      <div>
        <AppSidebar />
        <br></br>
        <h3>Gestion de la documentation pour les bénévoles</h3>
        <CButton color="success" style={{ marginBottom: '10px' }} onClick={() => setModalCourriel(true)}>Créer</CButton>
        <br />
        <CAccordion flush>
          {documentation.map(documentations => (
            <CAccordionItem key={documentations.id} onClick={() => alertModifier(documentations.id)}>
              <CAccordionHeader>{documentations.titre}</CAccordionHeader>
              <CAccordionBody>
                <CAlert visible={alert} color="success">La documentation a bien été modifié</CAlert>
                <CForm>
                  <CRow className="mb-3">
                    <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Titre</CFormLabel>
                    <CCol sm={10} >
                      <CFormInput id="inputEmail3" value={documentations.titre} onChange={(e) => changerTitre(documentations.id, e.target.value)} />
                    </CCol>
                  </CRow>
                  <CRow className="mb-3">
                    <CFormTextarea
                      id="exampleFormControlTextarea1"
                      label="Description"
                      rows={3}
                      value={documentations.description}
                      onChange={(e) => changerDescription(documentations.id, e.target.value)}
                    ></CFormTextarea>
                  </CRow>
                  <CRow className="mb-3">
                    <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Lien</CFormLabel>
                    <CCol sm={10} >
                      <CFormInput id="inputEmail3" value={documentations.lien} onChange={(e) => changerLien(documentations.id, e.target.value)} />
                    </CCol>
                  </CRow>
                  <br />
                  <CButton type="submit" onClick={(e) => changerDocumentation(e)}>Modifier</CButton>
                  <CButton color="danger" style={{ marginLeft: '10px' }} onClick={() => setModalSupprimer(true)}>Supprimer</CButton>
                </CForm>
              </CAccordionBody>
            </CAccordionItem>
          ))}

        </CAccordion>

        <CModal visible={modalCourriel}>
          <CModalHeader>
            <CModalTitle>Ajouter une nouvelle documentation:</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CFormLabel>Titre</CFormLabel>
            <CFormInput
              onChange={(e) => setNouveauTitre(e.target.value)}
            ></CFormInput>
            <CFormLabel>Description</CFormLabel>
            <CFormTextarea
              onChange={(e) => setNouvelleDescription(e.target.value)}>
            </CFormTextarea>
            <CFormLabel>Lien de redirection</CFormLabel>
            <CFormInput
              onChange={(e) => setNouveauLien(e.target.value)}
            ></CFormInput>
            <br />
            <CAlert visible={alertCreer} color="success">La documentation a été créé</CAlert>
          </CModalBody>
          <CModalFooter>

            <CButton onClick={fermerModal}>
              Fermer
            </CButton>
            <CButton
              color="success"
              onClick={creerDocumentation}
            >
              Créer
            </CButton>
          </CModalFooter>
        </CModal>

        <CModal visible={modalSupprimer}>
          <CModalTitle style={{ padding: '10px' }}>Supprimer la documentation</CModalTitle>
          <CModalBody>Êtes-vous vraiment sûr de vouloir supprimer ?</CModalBody>
          <CModalFooter>
            <CButton
              onClick={() => setModalSupprimer(false)}
            >
              Fermer
            </CButton>
            <CButton
              color="danger"
              onClick={() =>
                supprimer(
                  idDocumentationSelectionner
                )
              }
            >
              Supprimer
            </CButton>
          </CModalFooter>
        </CModal>
      </div>
    </>
  )
}