/*
 nom du fichier : ModificationDocumentation.js
 fonction: Tableau de bord pour les pages de Documentation


 Auteur : Maxence

*/

import React, { useEffect, useState } from 'react';
import '../../scss/style.scss'

import {
  CButton,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CFormTextarea,
  CFormSelect,
  CForm,
  CAlert,
  CCol,
  CRow,
  CModal,
  CModalFooter,
  CModalBody,
  CModalTitle,
  CFormInput,
  CFormLabel,
  CInputGroup,
} from '@coreui/react'


import { AppHeader, AppFooter, AppSidebar } from 'src/components/Administrateur'
import Authentification from 'src/hooks/Authentification'
import axios from 'axios';
import axiosInstance from 'src/hooks/axios';

import DateAdmin from './DateAdmin';

function ActiviteAdmin() {

  const [activite, setActivite] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertCreer, setAlertCreer] = useState(false);
  const [alertCreerDate, setAlertCreerDate] = useState(false);
  const [nombreInput, setNombreInput] = useState(0);
  const [valeurInput, setValeurInput] = useState([{ date: '', text: '' }]);

  const [modalActivite, setModalActivite] = useState(false);

  const [nouvelleActivite, setNouvelleActivite] = useState({
    nomEvent: '',
    description: '',
    tarif: '',
    horaire: '',
    lieu: '',
    idRegion: ''
  })

  const [modalSupprimer, setModalSupprimer] = useState(false);

  const [regionSansActivite, setRegionSansActivite] = useState([]);

  const [idActiviteSelectionner, setIdActiviteSelectionner] = useState(0);


  useEffect(() => {

    const fetch = async () => {
      axios.get(`https://api.chat.allaitementquebec.org/activite`).then(res => {
        setActivite(res.data);
      });
      axiosInstance.get(`/regionSansActivite`).then(res => {
        setRegionSansActivite(res.data);
      })
    }
    fetch();
  }, []);


  const changerNom = (id, e) => {
    const nouveauNomEvent = e.target.value;
    setActivite(prevActivite => {
      return prevActivite.map(activite => {
        if (activite.id === id) {
          return { ...activite, nomEvent: nouveauNomEvent }
        } else {
          return activite
        }
      })
    })
  }

  const changerDescription = (id, e) => {
    const nouvelleDescription = e.target.value;

    setActivite(prevActivite => {
      return prevActivite.map(activite => {
        if (activite.id === id) {
          return { ...activite, description: nouvelleDescription }
        } else {
          return activite
        }
      })
    })
  }

  const changerTarif = (id, e) => {
    const nouveauTarif = e.target.value;
    setActivite(prevActivite => {
      return prevActivite.map(activite => {
        if (activite.id === id) {
          return { ...activite, tarif: nouveauTarif }
        } else {
          return activite
        }
      })
    })
  }
  const changerHoraire = (id, e) => {
    const nouvelleHoraire = e.target.value;
    setActivite(prevActivite => {
      return prevActivite.map(activite => {
        if (activite.id === id) {
          return { ...activite, horaire: nouvelleHoraire }
        } else {
          return activite
        }
      })
    })
  }

  const changerLieu = (id, e) => {
    const nouveauLieu = e.target.value;
    setActivite(prevActivite => {
      return prevActivite.map(activite => {
        if (activite.id === id) {
          return { ...activite, lieu: nouveauLieu }
        } else {
          return activite
        }
      })
    })
  }

  const changerRegion = (id, e) => {
    // const nouvelleRegion = e.target.value;

    setActivite(prevActivite => {
      return prevActivite.map(activite => {
        if (activite.id === id) {
          return { ...activite, idRegion: e }
        } else {
          return activite
        }
      })
    })
  }


  const modifierActivite = async (e) => {
    e.preventDefault();

    const ativiteSelectionner = activite.find((item) => item.id === idActiviteSelectionner);

    const { nomEvent, description, tarif, horaire, lieu, idRegion } = ativiteSelectionner;

    await axiosInstance.put(`/changerActivite/${idActiviteSelectionner}`, { nomEvent, description, tarif, horaire, lieu, idRegion });
    setAlert(true)

  }


  const supprimer = (id) => {
    axiosInstance.delete(`/supprimerActivite/${id}`).then(() => { window.location.reload() });
  }

  const creerActivite = async (e) => {
    e.preventDefault();

    const { nomEvent, description, tarif, horaire, lieu, idRegion } = nouvelleActivite;

    await axiosInstance.post(`/creerActivite`, { nomEvent, description, tarif, horaire, lieu, idRegion: idRegion || null });

    setAlertCreer(true);

    insererDate();
  }

  const insererDate = (e) => {
    valeurInput.forEach((value, index) => {
      const dateObject = new Date(Date.parse(value.date));
      const formattedDate = dateObject.toISOString().split('T')[0];
      const lienZoom = value.text;
      axiosInstance.post(`/creerDate`, { date: formattedDate, lienZoom })
    })

    setValeurInput([{ date: '', text: '' }]);
    setNombreInput(0);
  }

  const fermerModal = async () => {
    setModalActivite(false);
    setAlertCreer(false);
    window.location.reload();
  }

  const ajoutInput = () => {
    setNombreInput(prevNombreInput => prevNombreInput + 1);
    setValeurInput(prevValeurInput => [...prevValeurInput, { date: '', text: '' }]);
  }


  const supprimerInput = (index) => {
    setNombreInput(prevNombreInput => prevNombreInput - 1);
    setValeurInput(prevValeurInput => prevValeurInput.filter((_, i) => i !== index));
  }

  const handleDateChange = (index, dateValue) => {
    setValeurInput(prevValeurInput => {
      const newValeurInput = [...prevValeurInput];
      newValeurInput[index].date = dateValue;
      return newValeurInput;
    });
  }

  const handleTextChange = (index, textValue) => {
    setValeurInput(prevValeurInput => {
      const newValeurInput = [...prevValeurInput];
      newValeurInput[index].text = textValue;
      return newValeurInput;
    });
  }

  const creerDate = async() => {
    valeurInput.forEach((value, index) => {
      const dateObject = new Date(value.date);
      const formattedDate = dateObject.toISOString().split('T')[0];
      const lienZoom = value.text;
      axiosInstance.post(`/ajouterDate`, { date: formattedDate, lienZoom: lienZoom, idActivite: idActiviteSelectionner })
      setAlertCreerDate(true);
    });

    setValeurInput([{ date: '', text: '' }]);
    setNombreInput(0);

    window.location.reload();
  }

  const accordion = async (id) => {
    setIdActiviteSelectionner(id);
    setAlert(false);
  }


  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <h4>Gestions des activités</h4>

            <CButton color="success" style={{ marginBottom: '10px' }} onClick={() => setModalActivite(true)}>Créer</CButton>
            <br></br>
            <CAccordion flush>
              {activite.map(activites => (
                <CAccordionItem key={activites.id} onClick={() => accordion(activites.id)}>
                  <CAccordionHeader>{activites.nomEvent} - {activites.nomRegion} - {activites.type}</CAccordionHeader>
                  <CAccordionBody>
                    <CAlert visible={alert} color='success'>
                      L'activité a bien été modifiée
                    </CAlert>
                    <CForm>
                      <CRow className="mb-3">
                        <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Nom :</CFormLabel>
                        <CCol sm={10} >
                          <CFormInput id="inputEmail3" value={activite.find(laActivite => laActivite.id === activites.id)?.nomEvent} onChange={(e) => changerNom(activites.id, e)} />
                        </CCol>
                      </CRow>
                      <CRow className="mb-3">
                        <CFormTextarea
                          id="exampleFormControlTextarea1"
                          label="Description de l'activité"
                          rows={3}
                          value={activite.find(Activite => Activite.id === activites.id)?.description}
                          onChange={(e) => changerDescription(activites.id, e)}
                        ></CFormTextarea>
                      </CRow>
                      <CRow className="mb-3">
                        <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Tarif :</CFormLabel>
                        <CCol sm={10} >
                          <CFormInput id="inputEmail3" value={activite.find(Activite => Activite.id === activites.id)?.tarif} onChange={(e) => changerTarif(activites.id, e)} />
                        </CCol>
                      </CRow>
                      <CRow className="mb-3">
                        <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Horaire : </CFormLabel>
                        <CCol sm={10} >
                          <CFormInput id="inputEmail3" value={activite.find(Activite => Activite.id === activites.id)?.horaire} onChange={(e) => changerHoraire(activites.id, e)} />
                        </CCol>
                      </CRow>
                      <CRow className="mb-3">
                        <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Lieu : </CFormLabel>
                        <CCol sm={10} >
                          <CFormInput id="inputEmail3" value={activite.find(Activite => Activite.id === activites.id)?.lieu} onChange={(e) => changerLieu(activites.id, e)} />
                        </CCol>
                      </CRow>

                      <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Région : </CFormLabel>
                      <CFormSelect aria-label="Default select example" onChange={(e) => changerRegion(activites.id, e.target.value)}>
                        <option value="" defaultValue>-- Sélectionner une nouvelle région --</option>
                        {regionSansActivite.map(regions => {
                          return (
                            <option key={regions.id} value={regions.id}>
                              {regions.nomRegion} - {regions.type}
                            </option>
                          )
                        })}
                      </CFormSelect>

                      <p>La région définie la langue de l'activité</p>
                      <br></br>
                      <CButton type="submit"
                        onClick={(e) => modifierActivite(e)}>Modifier</CButton>
                      <CButton color="danger" style={{ marginLeft: '10px' }} onClick={() => setModalSupprimer(true)}>Supprimer</CButton>
                      <br></br>
                      <hr></hr>
                      <DateAdmin setIdActiviteSelectionner={idActiviteSelectionner} />
                      <hr></hr>

                      <CButton color="warning" onClick={ajoutInput}>Ajouter des dates</CButton>
                      <CButton color="success" style={{ marginLeft: '10px' }} onClick={creerDate}>Créer les dates</CButton>
                      <br></br>
                      <br></br>
                      {Array.from({ length: nombreInput }).map((_, index) => (
                        <div key={index}>
                          <CInputGroup className="mb-3">
                            <CFormInput type="date" value={valeurInput[index].date} onChange={(e) => handleDateChange(index, e.target.value)} />
                            <CFormInput type="text" value={valeurInput[index].text} placeholder='Ajouter un lien zoom si besoin' onChange={(e) => handleTextChange(index, e.target.value)} />
                            <CButton type="button" color="danger" id="button-addon2" onClick={() => supprimerInput(index)}>Supprimer</CButton>
                          </CInputGroup>
                          <br></br>
                        </div>
                      ))}
                      <br></br>
                      <CAlert visible={alertCreerDate} color="success">Nouvelles dates ajoutées. Recharger la page pour les voir</CAlert>
                      <br></br>
                    </CForm>
                  </CAccordionBody>
                </CAccordionItem>
              ))}

            </CAccordion>

            <CModal
              visible={modalActivite}
            >

              <CModalTitle style={{ padding: '10px' }}>Créer une nouvelle activité</CModalTitle>
              <hr></hr>
              <CModalBody>
                <CFormLabel>Nom de l'activité</CFormLabel>
                <CFormInput onChange={(e) => setNouvelleActivite(prevState => ({ ...prevState, nomEvent: e.target.value }))} required />
                <CFormLabel>Description de l'activité</CFormLabel>
                <CFormInput onChange={(e) => setNouvelleActivite(prevState => ({ ...prevState, description: e.target.value }))} required />
                <CFormLabel>Tarif</CFormLabel>
                <CFormInput onChange={(e) => setNouvelleActivite(prevState => ({ ...prevState, tarif: e.target.value }))} required />
                <CFormLabel>Horaire</CFormLabel>
                <CFormInput onChange={(e) => setNouvelleActivite(prevState => ({ ...prevState, horaire: e.target.value }))} required />

                <CFormLabel>Lieu</CFormLabel>
                <CFormInput onChange={(e) => setNouvelleActivite(prevState => ({ ...prevState, lieu: e.target.value }))} required />
                <CFormLabel>Région</CFormLabel>
                <CFormSelect aria-label="Default select example" onChange={(e) => setNouvelleActivite(prevState => ({ ...prevState, idRegion: e.target.value }))}>
                  <option defaultValue={null}>
                    --Sélectionnez une région--
                  </option>
                  {regionSansActivite.map(regions => {
                    return (
                      <option key={regions.id} value={regions.id}>
                        {regions.nomRegion} - {regions.type}
                      </option>
                    )
                  })}
                  {/* Render options for regions */}
                </CFormSelect>

                <hr></hr>
                <CButton color="warning" onClick={ajoutInput}>Ajouter des dates</CButton>
                <br></br>
                <br></br>
                {Array.from({ length: nombreInput }).map((_, index) => (
                  <div key={index}>
                    <CInputGroup className="mb-3">
                      <CFormInput type="date" value={valeurInput[index].date} onChange={(e) => handleDateChange(index, e.target.value)} />
                      <CFormInput type="text" value={valeurInput[index].text} placeholder='Ajouter un lien zoom si besoin' onChange={(e) => handleTextChange(index, e.target.value)} />
                      <CButton type="button" color="danger" id="button-addon2" onClick={() => supprimerInput(index)}>Supprimer</CButton>
                    </CInputGroup>
                    <br></br>
                  </div>
                ))}
                <br />
                <CAlert visible={alertCreer} color="success">L'activité et les dates ont bien été créées</CAlert>
              </CModalBody>
              <CModalFooter>
                <CButton onClick={fermerModal}>Fermer</CButton>
                <CButton color="success" onClick={creerActivite}>
                  Créer
                </CButton>
              </CModalFooter>
            </CModal>

            <CModal visible={modalSupprimer}>
              <CModalTitle style={{ padding: '10px' }}>Supprimer l'activité</CModalTitle>
              <CModalBody>Êtes-vous vraiment sûr de vouloir supprimer ?</CModalBody>
              <CModalFooter>
                <CButton
                  onClick={() => setModalSupprimer(false)}
                >
                  Fermer
                </CButton>
                <CButton
                  color="danger"
                  onClick={() =>
                    supprimer(
                      idActiviteSelectionner
                    )
                  }
                >
                  Supprimer
                </CButton>
              </CModalFooter>
            </CModal>

          </div>
        </div>
        <AppFooter />
      </div>
    </>
  )
}

export default Authentification(ActiviteAdmin, [3]);