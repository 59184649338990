/*
 nom du fichier : Connexion.js
 fonction: ce fichier represente la connexion des utilisateurs

 auteur : maxence mazeau.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css

*/

import './MotDePasseOublie.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'

function MotDePasseOublie() {

  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [courriel, setCourriel] = useState('');

  function MonLienInscriptionParent() {
    window.location.href = '/InscriptionParent'
  }

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(`https://api.chat.allaitementquebec.org/courrielMotDePasse`);
      setTitre(res.data[0].titre);
      setDescription(res.data[0].description);
    }

    fetch();

  }, []);

  console.log(titre);

  const envoiCourrielMotDePasse = async (e) => {
    e.preventDefault();
    const uuid = uuidv4();
    const timeStamp = Date.now();
    const timeStampExpiration = timeStamp + 15 * 60 * 1000;

    const creerA = new Date(timeStamp).toISOString().slice(0, 19).replace('T', ' ');
    const expiration = new Date(timeStampExpiration).toISOString().slice(0, 19).replace('T', ' ');

    await axios.get(`https://api.chat.allaitementquebec.org/utilisateurCourriel/${courriel}`).then(res => {
      axios.post(`https://api.chat.allaitementquebec.org/lienRecuperation/`, { token: uuid, creerA, expiration, idUtilisateur: res.data[0].id })
    });

    const lienUnique = `https://chat.allaitementquebec.org/RecuperationMdp/${uuid}`;
    await axios.post('https://api.chat.allaitementquebec.org/motDePasseOublie', {
      to: courriel,
      subject: titre,
      body: ` ${description} : <a href="${lienUnique}">Changer mon mot de passe</a>`
    });
    alert('Un courriel pour modifier votre mot de passe a été envoyer. Pensez à regarder vos spams');
    window.location.href = '/Connexion';
  }


  return (
    <div className="mainMotDePasseOublie">
      <div className="form-areaMotDePasseOublie row col-12 ">

        <div className="row g-0 bg-transparent d-flex ">
          <h3>  <FontAwesomeIcon icon={faCaretLeft} />Modification du mot de passe</h3>
          <div className="col-lg-5 single-form ">
            <div className="rightMotDePasseOublie">
              <form action="post" >
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Courriel:
                  </label>
                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={(e) => setCourriel(e.target.value)} required />
                </div>
                <div className="btnSubmitMotDePasseOublie">
                  <button type="submit" className="btn btn-primary rounded" onClick={envoiCourrielMotDePasse}>
                    Récupérer le compte
                  </button>
                </div>
              </form>
              <div className="spanFomulaireMotDePasseOublie">
                <span onClick={MonLienInscriptionParent} >Inscrivez-vous pour pouvoir clavarder avec nos bénévoles.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MotDePasseOublie
