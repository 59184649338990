/*
 nom du fichier : Profil.js
 fonction: ce fichier represente les informations du bénévole qu'il peut modifier

 auteur : maxence mazeau.

*/

import React, { useState, useEffect } from 'react'
import axiosInstance from 'src/hooks/axios';
import './Profil.css'
import DOMPurify from 'dompurify';

import {
  CAlert,
} from '@coreui/react'
import Authentification from 'src/hooks/Authentification'

function ProfilBenevole() {

  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
  const idRole = utilisateur.idRole;
  if (idRole !== 2) {
    window.location.href = '/Error404';
  }

  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [courriel, setCourriel] = useState('')
  const [telephone, setTelephone] = useState('')
  const [dateNaissance, setDateNaissance] = useState('')
  const [langue, setLangue] = useState('')

  const [erreur, setErreur] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState(false);
  const [dureeTotalAllaitement, setDureeTotalAllaitement] = useState('')
  const [experience, setExperience] = useState('')
  const [nouveauMotDePasse, setNouveauMotDePasse] = useState('')
  const [nouveauMotDePasseConfirmer, setNouveauMotDePasseConfirmer] = useState('')

  // let utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'))
  let id = utilisateur.id

  useEffect(() => {
    const fetch = async () => {

      const res = await axiosInstance.get(`/utilisateur/${id}`)
      setNom(res.data[0].nom)
      setPrenom(res.data[0].prenom)
      setCourriel(res.data[0].courriel)
      setTelephone(res.data[0].telephone)
      setDateNaissance(res.data[0].dateNaissance)

      const response = await axiosInstance.get(`/InfoBenevole/${id}`)
      setLangue(response.data[0].langue)
      setDureeTotalAllaitement(response.data[0].dureeTotalAllaitement)
      setExperience(response.data[0].experience)
    }

    fetch()
  }, [id])

  const changementProfil = (e) => {
    e.preventDefault()
    axiosInstance.put(`/changementProfil/${id}`, {
      nom,
      prenom,
      courriel,
      telephone,
      dateNaissance,
    }).then(setConfirmation(true))
  }

  const changementInfoBenevole = (e) => {
    e.preventDefault()

    axiosInstance.put(`/changementInfoBenevole/${id}`, {
      experience,
      langue,
      dureeTotalAllaitement,
    }).then(setConfirmationInfo(true))
  }

  async function hashPassword(password) {
    const encoder = new TextEncoder();
    const data = encoder.encode(password);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hash = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
    return hash;
  }

  const changementMotDePasse = async() => {
    if (nouveauMotDePasse === nouveauMotDePasseConfirmer) {

      const hashedPassword = await hashPassword(nouveauMotDePasseConfirmer);

      await axiosInstance.put(`/changementMotDePasse/${id}`, {
        motDePasse: hashedPassword,
      }).then(alert('Votre mot de passe a bien été modifié'))
      setErreur('');
    } else {

      setErreur('Les mots de passe de correspondent pas')
    }
  }

  return (
    <div className="grid-container">
      <div className="grid-left">
        <form onSubmit={changementProfil}>
          <h1>Mes informations</h1>
          <CAlert visible={confirmation} color="success">Les modifications ont été enregistrées</CAlert>
          <div className="input-container">
            <label htmlFor="nom">Nom :</label>
            <div>
              <input id="nom" type="text" value={nom} onChange={(e) => setNom(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="prenom">Prénom : </label>
            <div>
              <input id="prenom" type="text" value={prenom} onChange={(e) => setPrenom(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="courriel">Courriel :</label>
            <div>
              <input id="courriel" type="email" value={courriel} onChange={(e) => setCourriel(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="courriel">Téléphone : </label>
            <div>
              <input id="courriel" type="tel" value={telephone} onChange={(e) => setTelephone(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="date">
              Date de naissance : {new Date(dateNaissance).toLocaleDateString()}{' '}
            </label>
            <div>
              <input id="date" type="date" onChange={(e) => setDateNaissance(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <button type="submit">Modifier</button>
          </div>
        </form>
      </div>
      <div className="grid-right-top">
        <h2>Mon expérience d'allaitement</h2>
        <CAlert visible={confirmationInfo} color="success">Les modifications ont été enregistrées</CAlert>
        <form onSubmit={changementInfoBenevole}>
          <div className="input-container">
            <label htmlFor="detail">
              Informations supplémentaire sur votre expérience d'allaitement et de bénévolat
            </label>
            <textarea
              type="text"
              id="detail"
              rows="4"
              value={experience}
              onChange={(e) => setExperience(DOMPurify.sanitize(e.target.value))}
            />
          </div>
          <div className="input-container">
            <label htmlFor="langue">Langue : </label>
            <div>
              <input id="langue" type="text" value={langue} onChange={(e) => setLangue(DOMPurify.sanitize(e.target.value))} />

            </div>
          </div>
          <div className="input-container">
            <label htmlFor="duree">Durée total d'allaitement : </label>
            <div>
              <input
                id="duree"
                type="text"
                value={dureeTotalAllaitement}
                onChange={(e) => setDureeTotalAllaitement(DOMPurify.sanitize(e.target.value))}
              />
            </div>
          </div>
          <div className="input-container">
            <button type="submit">Modifier</button>
          </div>
        </form>
      </div>
      <div className="grid-right-bottom">
        <h3>Changer mon mot de passe</h3>
        <div className="input-container">
          <p style={{ color: 'red' }}>{erreur}</p>
          <label>Nouveau mot de passe :</label>
          <div>
            <input
              id="motDePasse"
              type="password"
              value={nouveauMotDePasse}
              onChange={(e) => setNouveauMotDePasse(e.target.value)}
            />
          </div>
        </div>
        <div className="input-container">
          <label htmlFor="motDePasseConfirm">Confirmer le nouveau mot de passe :</label>
          <div>
            <input
              id="motDePasseConfirm"
              type="password"
              value={nouveauMotDePasseConfirmer}
              onChange={(e) => setNouveauMotDePasseConfirmer(e.target.value)}
            />
            <button onClick={changementMotDePasse}>Modifier</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Authentification(ProfilBenevole, [2]);

