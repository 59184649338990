/*
 nom du fichier : Footer.js
 fonction: ce fichier represente footer du site

 auteur : thanina adda.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css

*/

import React from 'react'
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function Footer() {
  return (
    <footer className="bg text-center ">
      <div className="containerFooter text-lg">
        <div className="footerBlock ">
          <div className="col-lg-4">
            <br></br>
            <h5 className="text-uppercase mb-4" style={{ color: 'lightslategray' }}>
              Liens utiles
            </h5>
            <ul className="list-unstyled mb-0">
              <li>
                <a href="https://www.paypal.com/donate?token=ila1_7HBHzPGxyVt4vJ_iLCY4ghYRp4RXZc1obBJmaQKxWqzz0O2gptrj4zMQYEWtzWUFfTM5aKcAazc" className="text-white">
                  Pour faire un don à Allaitement Québec
                </a>
              </li>
              <li>
                <a href="https://allaitementquebec.org" className="text-white">
                  Site web
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4" style={{ textAlign: 'center' }}>
            <br></br>
            <img
              src="./assets/img/allaitement/Logo.png"
              alt="Logo"
              className="img-fluid"
            />
          </div>

          <div className="col-lg-4 footerDroite">
            <br></br>
            <h5 className="text-uppercase mb-4" style={{ color: 'lightslategray' }}>
              Nous suivre
            </h5>
            <ul className="list-unstyled mb-0">
              <li>
                <a href="https://www.facebook.com/AllaitementQuebec/" className="text-white">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/allaitement.quebec/" className="text-white">
                  Instagram
                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div>

        <p className='droitDauteur' style={{ textAlign: 'center', color: 'lightslategrey' }}>Programmeurs:
          <a href='https://www.linkedin.com/in/thanina-adda-a82972253/' className="text-white">Thanina Adda</a>,
          <a href='https://www.linkedin.com/in/maxence-mazeau-686594197/' className="text-white">Maxence Mazeau</a>,
          <a href='https://www.linkedin.com/in/florian-hostachy-b72b91274/' className="text-white">Florian Hostachy</a>.</p>
      </div>
    </footer>
  )
}

export default Footer
