/*
 nom du fichier : ModificationDocumentation.js
 fonction: Tableau de bord pour les pages de Documentation
 Auteur : Maxence
*/
import React, { useEffect, useState } from 'react';
import '../../scss/style.scss'
import DocumentationBenevole from './DocumentationBenevole';
import DocumentationParent from './DocumentationParent';

import {
  CButton,
  CButtonGroup,
} from '@coreui/react'

import { AppFooter, AppHeader, AppSidebar } from 'src/components/Administrateur'
import Authentification from 'src/hooks/Authentification'

function ModificationDocumentation(){
    const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
    const idRole = utilisateur ? utilisateur.idRole : null;
    if (idRole !== 3) {
        window.location.href = '/Error404';
      }

    const[documentationBenevole, setDocumentationBenevole] = useState(false);
    const[documentationParent, setDocumentationParent] = useState(false);

    useEffect(() => {
        setDocumentationParent(true);
    }, [])

    const PageDocumentationBenevole = () => {
        
        setDocumentationBenevole(true);
        setDocumentationParent(false);
    }

    const PageDocumentationParent = () => {
        if(documentationParent !== true){
            setDocumentationParent(true);
        } else {
            setDocumentationParent(false)
        }
        setDocumentationParent(true);
        setDocumentationBenevole(false);
        
    }

    return(
        <>
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader/>
            <div className="body flex-grow-1 px-3">
                
            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                <CButton color="primary" onClick={PageDocumentationParent}>Parent</CButton>
                <CButton color="secondary" onClick={PageDocumentationBenevole}>Bénévole</CButton>
            </CButtonGroup>

                    {documentationParent ? <DocumentationParent /> : null}
                    {documentationBenevole ? <DocumentationBenevole /> : null}         
            </div>
            </div>

            <AppFooter />
        </div>
        </>
    )
}

export default Authentification(ModificationDocumentation, [3]);