///////////////////////////////////////////////////
// Auteur : Florian Hostachy
// Date : 4/12/2023
// Fonction : Interface du coté admin plus modification et supression des comptes 
// Version : 1.2
///////////////////////////////////////////////////

import React, { Component } from 'react'
import axiosInstance from 'src/hooks/axios';
import '../../scss/style.scss'

import {
  CCard,
  CButton,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalFooter,
  CModalBody,
  CModalTitle,
  CFormInput,
  CFormLabel,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

import { cilPeople } from '@coreui/icons'
import { AppFooter, AppHeader, AppSidebar } from 'src/components/Administrateur'
import Authentification from 'src/hooks/Authentification'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      benevole: [],
      parent: [],
      supBeneVisible: false,
      visible: false,
      selectedUser: null,
      modifierClient: false,
      modifierBenevole: false,
      valeur: '',
      modalConfirmer: false,
      modalDesactiver : false,
      idBenevole: null,
      supParentVisible: false,
    }
  }

  handleChange(event) {
    const { name, value } = event.target
    this.setState((prevState) => ({
      selectedUser: { ...prevState.selectedUser, [name]: value },
    }))
  }

  // Permet de remplir les deux tableau avec les parents et les bénévoles
  componentDidMount() {
    axiosInstance.get('/utilisateurBenevole').then((res) => {
      this.setState({
        benevole: res.data,

      })
    })
    axiosInstance.get('/utilisateurParent').then((response) => {
      this.setState({
        parent: response.data,
      })

    })
  }

  selectParentMod = (item) => {

    this.setState({ selectedUser: item, modifierClient: true })
  }

  selectClientSup = (item) => {

    this.setState({ selectedUser: item, supParentVisible: true })
  }

  selectBenevoleMod = (item) => {

    this.setState({ selectedUser: item, modifierBenevole: true })
  }
  selectBenevoleSup = (item) => {

    this.setState({ selectedUser: item, supBeneVisible: true })
  }

  modifierUtilisateur = (id, nom, prenom, telephone, courriel) => {
    const data = {
      nom,
      prenom,
      telephone,
      courriel,
    }

    axiosInstance
      .put(`/modifierUtilisateur/${id}`, data)
      .then((response) => response.data)

    window.location.reload()
  }

  supprimerUtilisateur = (id) => {
    axiosInstance
      .delete(`/supprimerUtilisateur/${id}`)
      .then((response) => {

        window.location.reload()
      })
  }

  modalConfirmerBenevole = (id) => {
    this.setState({ modalConfirmer: true, idBenevole: id });
  }

  fermerModalConfirmerBenevole = () => {
    this.setState({ modalConfirmer: false });
  }

  modalDesactiverBenevole = (id) => {
    this.setState({ modalDesactiver: true, idBenevole: id });
  }

  fermerModalDesactiveBenevole = () => {
    this.setState({ modalDesactiver: false });
  }

  rendreBenevoleActif = async(id) => {
    await axiosInstance.put(`/benevoleActif/${id}`);
    this.setState({ modalConfirmer: false });
    window.location.reload();
  }

  rendreBenevoleInactif = async(id) => {
    await axiosInstance.put(`/benevoleInactif/${id}`);
    this.setState({ modalConfirmer: false });
    window.location.reload();
  }

  render() {
    const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
    const idRole = utilisateur.idRole;
    if (idRole !== 3) {
    
      window.location.href = '/Error404';
    }
    const { benevole } = this.state
    const { parent } = this.state
    this.handleChange = this.handleChange.bind(this)
    return (
      <>
        <div>
          <AppSidebar />
          <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader />
            <div className="body flex-grow-1 px-3">
              <CRow>
                <CCol xs>
                  <CCard className="mb-4">
                    <CCardHeader>Bénévoles</CCardHeader>
                    <CCardBody>
                      <CTable align="middle" className="mb-0 border" hover responsive>
                        <CTableHead color="light">
                          <CTableRow>
                            <CTableHeaderCell className="text-center">
                              <CIcon icon={cilPeople} />
                            </CTableHeaderCell>
                            <CTableHeaderCell>Nom</CTableHeaderCell>
                            <CTableHeaderCell>Prénom</CTableHeaderCell>
                            <CTableHeaderCell>Téléphone</CTableHeaderCell>
                            <CTableHeaderCell>Courriel</CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {benevole.map((item, index) => (
                            <CTableRow v-for="item in tableItems" key={item.id}>
                              <CTableDataCell className="text-center">
                                <div></div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.nom}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.prenom}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.telephone}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.courriel}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                {item.statutCompte !== 1 ?
                                  <CButton color="dark" onClick={() => this.modalConfirmerBenevole(item.id)}>Inactif</CButton>
                                  :
                                  <CButton color="info" onClick={() => this.modalDesactiverBenevole(item.id)}>Actif</CButton>
                                }
                              </CTableDataCell>
                              <CTableDataCell>
                                <CButton onClick={() => this.selectBenevoleMod(item)}>
                                  Modifier
                                </CButton>
                              </CTableDataCell>

                              <CModal visible={this.state.modalDesactiver}>
                                  <CModalBody>
                                    <CFormLabel>Voulez-vous désactiver ce bénévole ?</CFormLabel>
                                    <br></br>
                                    <CButton type="submit" color="success" style={{ marginLeft: '10px' }} onClick={() => this.rendreBenevoleInactif(this.state.idBenevole)}>Oui</CButton>
                                    <CButton type="submit" color="danger" style={{ marginLeft: '10px' }} onClick={() => this.fermerModalDesactiveBenevole()}>Non</CButton>
                                  </CModalBody>
                              </CModal>
                              <CModal visible={this.state.modalConfirmer}>    
                                  <CModalBody>
                                    <CFormLabel>Voulez-vous activer ce bénévole ?</CFormLabel>
                                    <br></br>
                                    <CButton type="submit" color="success" style={{ marginLeft: '10px' }} onClick={() => this.rendreBenevoleActif(this.state.idBenevole)}>Oui</CButton>
                                    <CButton type="submit" color="danger" style={{ marginLeft: '10px' }} onClick={() => this.fermerModalConfirmerBenevole()}>Non</CButton>

                                  </CModalBody>
                                
                              </CModal>

                              <CModal visible={this.state.modifierBenevole}>
                              <CModalTitle style={{padding: '10px'}}>Modifier le bénévole</CModalTitle>
                                <CModalBody>
                                  <CFormLabel>Nom : </CFormLabel>
                                  <CFormInput
                                    name="nom"
                                    value={this.state.selectedUser ? this.state.selectedUser.nom : ''}
                                    onChange={this.handleChange}
                                  ></CFormInput>
                                  <CFormLabel>Prenom : </CFormLabel>
                                  <CFormInput
                                    name="prenom"
                                    value={this.state.selectedUser ? this.state.selectedUser.prenom : ''}
                                    onChange={this.handleChange}
                                  ></CFormInput>
                                  <br />
                                  <CFormLabel>Téléphone : </CFormLabel>
                                  <CFormInput
                                    name="telephone"
                                    value={
                                      this.state.selectedUser ? this.state.selectedUser.telephone : ''
                                    }
                                    onChange={this.handleChange}
                                  ></CFormInput>
                                  <br />
                                  <CFormLabel>Courriel : </CFormLabel>
                                  <CFormInput
                                    name="courriel"
                                    value={
                                      this.state.selectedUser ? this.state.selectedUser.courriel : ''
                                    }
                                    onChange={this.handleChange}
                                  ></CFormInput>
                                </CModalBody>
                                <CModalFooter>
                                  <CButton type="submit" onClick={() => this.setState({ modifierBenevole: false })}>
                                    Fermer
                                  </CButton>
                                  <CButton
                                    color="success"
                                    onClick={() =>
                                      this.modifierUtilisateur(
                                        this.state.selectedUser.id,
                                        this.state.selectedUser.nom,
                                        this.state.selectedUser.prenom,
                                        this.state.selectedUser.telephone,
                                        this.state.selectedUser.courriel,
                                      )
                                    }
                                  >
                                    Modifier
                                  </CButton>
                                </CModalFooter>
                              </CModal>
                              <CTableDataCell>
                                <CButton
                                  color="danger"
                                  onClick={() => {
                                    this.setState({ supBeneVisible: true });
                                    this.setState({ selectedUser: item });
                                  }}
                                >
                                  Supprimer
                                </CButton>
                                <CModal visible={this.state.supBeneVisible}>
                                <CModalTitle style={{padding: '10px'}}>Supprimer le bénévole</CModalTitle>
                                  <CModalBody>Êtes-vous vraiment sûr de vouloir supprimer le bénévole ?</CModalBody>
                                  <CModalFooter>
                                    <CButton
                                      onClick={() => this.setState({ supBeneVisible: false })}
                                    >
                                      Fermer
                                    </CButton>
                                    <CButton
                                      color="danger"
                                      onClick={() =>
                                        this.supprimerUtilisateur(
                                          this.state.selectedUser ? this.state.selectedUser.id : '',
                                        )
                                      }
                                    >
                                      Supprimer
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                        </CTableBody>
                      </CTable>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs>
                  <CCard className="mb-4">
                    <CCardHeader>Parents</CCardHeader>
                    <CCardBody>
                      <CTable align="middle" className="mb-0 border" hover responsive>
                        <CTableHead color="light">
                          <CTableRow>
                            <CTableHeaderCell className="text-center">
                              <CIcon icon={cilPeople} />
                            </CTableHeaderCell>
                            <CTableHeaderCell>Nom</CTableHeaderCell>
                            <CTableHeaderCell>Prénom</CTableHeaderCell>
                            <CTableHeaderCell>Téléphone</CTableHeaderCell>
                            <CTableHeaderCell>Courriel</CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                            <CTableHeaderCell></CTableHeaderCell>
                          </CTableRow>
                        </CTableHead>
                        <CTableBody>
                          {parent.map((item) => (
                            <CTableRow v-for="item in tableItems" key={item.id}>
                              <CTableDataCell className="text-center">
                                <div></div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.nom}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.prenom}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.telephone}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <div>{item.courriel}</div>
                              </CTableDataCell>
                              <CTableDataCell>
                                <CButton onClick={() => this.selectParentMod(item)}>
                                  Modifier
                                </CButton>
                                <CModal visible={this.state.modifierClient}>
                                <CModalTitle style={{padding: '10px'}}>Modifier le parent</CModalTitle>
                                  <CModalBody>
                                    <CFormLabel>Nom : </CFormLabel>
                                    <CFormInput
                                      name="nom"
                                      value={this.state.selectedUser ? this.state.selectedUser.nom : ''}
                                      onChange={this.handleChange}
                                    ></CFormInput>
                                    <CFormLabel>Prenom : </CFormLabel>
                                    <CFormInput
                                      name="prenom"
                                      value={
                                        this.state.selectedUser ? this.state.selectedUser.prenom : ''
                                      }
                                      onChange={this.handleChange}
                                    ></CFormInput>
                                    <br />
                                    <CFormLabel>Téléphone : </CFormLabel>
                                    <CFormInput
                                      name="telephone"
                                      value={
                                        this.state.selectedUser ? this.state.selectedUser.telephone : ''
                                      }
                                      onChange={this.handleChange}
                                    ></CFormInput>
                                    <br />
                                    <CFormLabel>Courriel : </CFormLabel>
                                    <CFormInput
                                      name="courriel"
                                      value={
                                        this.state.selectedUser ? this.state.selectedUser.courriel : ''
                                      }
                                      onChange={this.handleChange}
                                    ></CFormInput>
                                  </CModalBody>
                                  <CModalFooter>
                                    <CButton
                                      color="primary"
                                      onClick={() => this.setState({ modifierClient: false })}
                                    >
                                      Fermer
                                    </CButton>
                                    <CButton
                                      color="success"
                                      onClick={() =>
                                        this.modifierUtilisateur(
                                          this.state.selectedUser.id,
                                          this.state.selectedUser.nom,
                                          this.state.selectedUser.prenom,
                                          this.state.selectedUser.telephone,
                                          this.state.selectedUser.courriel,
                                        )
                                      }
                                    >
                                      Modifier
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                              </CTableDataCell>
                              <CTableDataCell>
                                <CButton
                                  color="danger"
                                  onClick={() => {
                                    this.setState({ visible: true });
                                    this.setState({ selectedUser: item });
                                  }}
                                >
                                  Supprimer
                                </CButton>
                                <CModal visible={this.state.visible}>
                                <CModalTitle style={{padding: '10px'}}>Supprimer le parent</CModalTitle>
                                  <CModalBody>Êtes-vous vraiment sûr de vouloir supprimer le parent ?</CModalBody>
                                  <CModalFooter>
                                    <CButton

                                      onClick={() => this.setState({ visible: false })}
                                    >
                                      Fermer
                                    </CButton>
                                    <CButton
                                      color="danger"
                                      onClick={() =>
                                        this.supprimerUtilisateur(
                                          this.state.selectedUser ? this.state.selectedUser.id : '',
                                        )
                                      }
                                    >
                                      Supprimer
                                    </CButton>
                                  </CModalFooter>
                                </CModal>
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                        </CTableBody>
                      </CTable>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </div>
            <AppFooter />
          </div>
        </div>
      </>
    )
  }
}

export default Authentification(Dashboard, [3]);
