// axiosInstance.js
import axios from 'axios';

const instance = axios.create({

  baseURL: 'https://api.chat.allaitementquebec.org/', // replace with the base URL of your API

});

instance.interceptors.request.use(
  config => {
    const utilisateur = sessionStorage.getItem('utilisateur');
    const parse = JSON.parse(utilisateur);
    const token = parse.token;

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
