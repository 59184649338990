import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from 'src/hooks/axios';
import io from 'socket.io-client'
import css from './Chat.module.css'
import DOMPurify from 'dompurify';
import InfoActivite from './InfoActivite';
import SupprimerActivite from './SupprimerActivite';
import Authentification from 'src/hooks/Authentification'

const socket = io.connect('https://api.chat.allaitementquebec.org/')

function Chat() {


  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
  const idUtilisateur = JSON.stringify(utilisateur.id)

  const [messages, setMessages] = useState([])
  const [idChat, setIdChat] = useState('')
  const [messageCount, setMessageCount] = useState(15)
  const [image, setImage] = useState(null);

  const [activiteParent, setActiviteParent] = useState([]);
  const [idDateActivite, setIdDateActivite] = useState('');
  const [modalOuvert, setModalOuvert] = useState(false);
  const [modalSupprimer, setModalSupprimer] = useState(false);

  const [activiteSelectionner, setActiviteSelectionner] = useState({
    nomEvent: '',
    description: '',
    date: '',
    horaire: '',
    region: '',
    lieu: '',
    lienZoom: '',
    tarif: ''
  })

  const [chatActif, setChatActif] = useState(false);
  let emetteur = 'parent'

  const [messageSocket, setMessageSocket] = useState([])

  const input = useRef()

useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get(`/dernierChat/${idUtilisateur}`);
      await axiosInstance.get(`/activiteParent/${idUtilisateur}`).then(response => {
        setActiviteParent(response.data);
      });

      const idChat = res.data[0].id;
      let idStatut = res.data[0].idStatut;

      if (idStatut !== 4) {
        setChatActif(true);
      } else {
        setChatActif(false);
      }

      sessionStorage.setItem('chat', idChat);
      setIdChat(idChat);
      socket.emit('regoinChat', idChat);
      //  sonNotification.current = new Audio(son);
    }

    const fetchMessages = async () => {
      const res = await axiosInstance.get(
        `/discussionHistorique/${idUtilisateur}?limit=${messageCount}`
      );

      const messagesPromises = res.data.map(async (message) => {
        if (message.image) {
          const blob = new Blob([new Uint8Array(message.image.data)], { type: message.image.type });
          const dataUrl = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (event) {
              const dataUrl = event.target.result;
              resolve(dataUrl);
            };
            reader.readAsDataURL(blob);
          });

          return { ...message, image: dataUrl };
        } else {
          return message;
        }
      });

      const messagesArray = await Promise.all(messagesPromises);
      setMessages(messagesArray);
    };

    fetch().then(() => { fetchMessages();

    socket.emit('regoinChat', idChat);

    socket.on("recoitMessage", (data) => {
      setMessageSocket((prevMessageSocket) => [...prevMessageSocket, data]);
    });

  });

    return () => {
      socket.off("recoitMessage");
    };

  }, [messageCount]);   

  const creerChat = async () => {
    setChatActif(true);
    await axiosInstance.post(`/creerChat`, { idUtilisateur });
    window.location.reload();

  }


  const Envoie = (e) => {
    e.preventDefault();
    const messageEnvoie = input.current.value;

    const message = DOMPurify.sanitize(messageEnvoie);

    setMessageSocket((prevMessageSocket) => [...prevMessageSocket, { message, emetteur }])

    socket.emit('envoieMessage', {
      message,
      idChat: idChat,
      emetteur,
    })
    envoieMessage(message);
  }

  const Image = (e) => {
    e.preventDefault();
    if (image) {
      image.click();
    }
  }

  const choixImage = (event) => {
    const fichier = event.target.files[0];
    if (fichier) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const imageBlob = new Blob([arrayBuffer], { type: fichier.type });

        // Create a Blob URL from the imageBlob
        const blobUrl = URL.createObjectURL(imageBlob);
        setImage(fichier);
        setMessageSocket((prevMessageSocket) => [...prevMessageSocket, { message: fichier, emetteur }]);
        // Emit the image as a message through the socket
        socket.emit('envoieMessage', {
          image: fichier,
          idChat: idChat,
          emetteur,
        });
        const formData = new FormData();
        formData.append("image", fichier);
        formData.append("idChat", idChat);
        formData.append("idUtilisateur", idUtilisateur);

        axiosInstance.post(`/envoiImage`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }).then(() => {
          event.target.value = "";
        })
      }
      reader.readAsDataURL(fichier);
      setImage(null);

    } else {
      setImage(null);
    }

  };

  const envoieMessage = (message) => {
    const idChat = sessionStorage.getItem('chat');
    axiosInstance.post('/envoiMessage', { message, idUtilisateur, idChat });

    input.current.value = "";
  }

  const loadMoreMessages = async () => {
    try {
      const res = await axiosInstance.get(
        `/discussionHistorique/${idUtilisateur}?limit=${messageCount + 15}`,
      )
      const olderMessages = res.data.reverse()
      setMessages([...olderMessages, ...messages])
      setMessageCount(messageCount + 15)
    } catch (error) {
    }
  }

  const ouvrirModal = (id, activiteSelectionner) => {
    setIdDateActivite(id);
    const activite = activiteParent.find(activite => activite.id === id);
    setActiviteSelectionner({
      nomEvent: activite.nomEvent,
      description: activite.description,
      date: activite.date,
      horaire: activite.horaire,
      region: activite.nomRegion,
      lieu: activite.lieu,
      lienZoom: activite.lienZoom,
      tarif: activite.tarif,
    });

    setModalOuvert(true);
  }

  const fermerModalActivite = () => {
    setModalOuvert(false);

  }

  const modalOuvrirSupprimer = (id) => {
    setModalSupprimer(true);
    const activite = activiteParent.find(a => a.id === id);
    setIdDateActivite(activite.idDateActivite);
  }

  const fermerModalSupprimer = async () => {
    setModalSupprimer(false);
    window.location.reload();
  }

  return (
    <div className={css.chatContainer}>
      <div className={css.sidebar}>
        <div className={css.row}>
          <h1>Mes activités</h1>
        </div>
        {activiteParent.map(activites => (
          <div key={activites.id} className={css.infoActivite} tabIndex="0">

            <h4 className={css.nom}>{activites.nomEvent}</h4>
            <p className={css.description}>{activites.description}</p>
            <p className={css.parentInfo} onClick={e => ouvrirModal(activites.id, activites.description, activites.nomEvent)}>Plus d'informations</p>
            <p className={css.date}>{new Date(activites.date).toLocaleDateString()}</p>
            <p className={css.supprimer} onClick={(e) => modalOuvrirSupprimer(activites.id)}>Supprimer</p>
          </div>

        ))}
        {modalOuvert && <InfoActivite fermerModalActivite={fermerModalActivite} activiteSelectionner={activiteSelectionner} />}
        {modalSupprimer && <SupprimerActivite fermerModalSupprimer={fermerModalSupprimer} idDateActivite={idDateActivite} idUtilisateur={idUtilisateur} />}
      </div>

      <div className={css.fenetreChat}>
        {messageCount <= messages.length ? (
          <button className={css.loadMoreButton} onClick={loadMoreMessages}>
            Charger plus de message
          </button>
        ) : null}

        {messages
          .slice(0)
          .reverse()
          .map((messages) => (
            <div
              key={messages.id}
              className={`${css.message} ${String(messages.idUtilisateur) !== idUtilisateur ? css.received : css.sent}`}
            >
              <div className={css.messageInfo}>
                {messages.image && (
                  <img className={css.image} src={messages.image} alt="" />
                )}
                {!messages.image && <p>{messages.message}</p>}
                <p className={css.messageTime}>{new Date(messages.moment).toLocaleString()}</p>
              </div>
            </div>
          ))}
        {messageSocket.map((messageSockets, index) => {
          return (
            <div
              className={`${css.message} ${messageSockets.emetteur === 'parent' ? css.sent : css.received}`}
              key={index}
            >
              <div className={css.messageInfo}>
                {messageSockets.message instanceof File && (
                  <img
                    className={css.image}
                    src={URL.createObjectURL(messageSockets.message)}
                    alt=""
                  />
                )}
                {!(messageSockets.message instanceof File) && (
                  <p>{messageSockets.message}</p>
                )}
                <p className={css.messageTime}>{new Date().toLocaleString()}</p>
              </div>
            </div>
          );
        })}
        {chatActif === true ? (
          <form className={css.newMessageForm}>
            <input
              type="text"
              placeholder="Ecrire un message..."
              ref={input}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  Envoie(e);
                }
              }}
            />

            <input
              type="file"
              ref={(elem) => setImage(elem)}
              accept="image/*"
              onChange={choixImage}
              style={{ display: 'none' }} // Hide the input element
            />
            <button type="file" onClick={Image}>
              Image
            </button>
            <button type="submit" onClick={Envoie}>
              Envoyer
            </button>
          </form>) : (
          <button className={css.buttonCreerChat} onClick={creerChat}>Commencer un nouveau clavadarge</button>
        )}
      </div>
    </div>
  )
}

export default Authentification(Chat, [1]);

