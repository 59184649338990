import React from 'react'



export default function Error404() {


  return (

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor:'whitesmoke' }}>
      <h2>Erreur 404 - Page non trouvé</h2>
    </div>
  )
} 