import React, {useState, useEffect} from 'react';
import axiosInstance from 'src/hooks/axios';

import '../.././../scss/style.scss'
import DateActivite from './DateActivite';

import {
    CAccordion,
    CAccordionHeader,
    CAccordionBody,
    CAccordionItem,
  } from '@coreui/react'
  import Authentification from 'src/hooks/Authentification'
  

import { AppHeader, AppFooter, AppSidebar } from 'src/components/Administrateur'

function InscriptoinActivite(){
    
    const [activite, setActivite] = useState([]);
    const [idActiviteSelectionner, setIdActiviteSelectionner] = useState(0);

    useEffect(() => {
        const fetch = async() => {
            const res =  await axiosInstance.get('/activitePrenatale');
            setActivite(res.data);  
          } 
          fetch();

    }, [idActiviteSelectionner])


    const activiteSelectionner = async (id) => {
        if (idActiviteSelectionner === id) {
            setIdActiviteSelectionner(0);   
        } else {
            setIdActiviteSelectionner(id);
        }
        
    };

    return(
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader/>
                    <div className="body flex-grow-1 px-3">
                        <h4>Listes des inscriptions aux activités</h4>
                        <CAccordion flush>
                            {activite.map(activites => (
                                <CAccordionItem key={activites.id} onClick={() => activiteSelectionner(activites.id)}>
                                    <CAccordionHeader>{activites.nomEvent} - {activites.nomRegion} - {activites.type}</CAccordionHeader>
                                        <CAccordionBody>
                                            <DateActivite idActiviteSelectionner={idActiviteSelectionner} />
                                           

                                        </CAccordionBody>
                                    </CAccordionItem>
                            ))}

                        </CAccordion>
                    </div>

            </div>

            <div>
                <AppFooter />
            </div>
        </div>
    )
}

export default Authentification(InscriptoinActivite, [3]);