/*
 nom du fichier : ModificationDocumentation.js
 fonction: Tableau de bord pour les pages de Documentation


 Auteur : Maxence

*/

import React, { useEffect, useState } from 'react';
import '../../scss/style.scss'
import Authentification from 'src/hooks/Authentification'

import {
  CButton,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CFormSelect,
  CForm,
  CCol,
  CRow,
  CModal,
  CModalFooter,
  CModalBody,
  CModalTitle,
  CFormInput,
  CFormLabel,
  CAlert,
} from '@coreui/react'


import { AppFooter, AppHeader, AppSidebar } from 'src/components/Administrateur'
import axios from 'axios';
import axiosInstance from 'src/hooks/axios';

function Region() {
  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
  const idRole = utilisateur.idRole;
  if (idRole !== 3) {

    window.location.href = '/Error404';
  }
  const [region, setRegion] = useState([]);

  const [alert, setAlert] = useState(false);
  const [alertCreer, setAlertCreer] = useState(false);
  const [modalSupprimer, setModalSupprimer] = useState(false);

  const [modalRegion, setModalRegion] = useState(false);

  const [typeActivite, setTypeActivite] = useState([]);

  const [nouvelleRegion, setNouvelleRegion] = useState({
    nomRegion: '',
    langue: '',
    idTypeActivite: '',
  });

  const [idRegionSelectionner, setIdRegionSelectionner] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      axios.get(`https://api.chat.allaitementquebec.org/region`).then(res => {
        setRegion(res.data);
      });
      axiosInstance.get(`/typeactivite`).then(res => {
        setTypeActivite(res.data);
      });
    }

    fetch();


  }, [])

  const changerNom = (id, nouveauNomRegion) => {
    setRegion(prevRegion => {
      return prevRegion.map(region => {
        if (region.id === id) {
          return { ...region, nomRegion: nouveauNomRegion }
        } else {
          return region
        }
      })
    })
  }

  const changerLangue = (id, e) => {

    const nouvelleLangue = e.target.value;
    setRegion(prevRegion => {
      return prevRegion.map(region => {
        if (region.id === id) {
          return { ...region, langue: nouvelleLangue }
        } else {
          return region
        }
      })
    })
  }

  const changeTypeActivite = (id, e) => {
    const nouveauType = e.target.value;

    setRegion(prevRegion => {
      return prevRegion.map(region => {
        if (region.id === id) {
          return { ...region, idTypeActivite: nouveauType }
        } else {
          return region
        }
      })
    })
  }

  const changerRegion = async (e) => {
    e.preventDefault();

    if (idRegionSelectionner === null) {

      return;
    }

    const regionSelectionner = region.find((item) => item.id === idRegionSelectionner);

    if (!idRegionSelectionner) {

      return;
    }

    const { nomRegion, langue } = regionSelectionner;

    // Get the updated idTypeActivite from the regionSelectionner object
    const idTypeActivite = regionSelectionner.idTypeActivite;

    await axiosInstance.put(`/changerRegion/${idRegionSelectionner}`, { nomRegion, langue, idTypeActivite });

    setAlert(true)
  }

  const supprimer = (id) => {
    axiosInstance.delete(`/supprimerRegion/${id}`).then(() => { window.location.reload() });
  }

  const creerRegion = async (e) => {

    const { nomRegion, langue, idTypeActivite } = nouvelleRegion;

    await axiosInstance.post(`/creerRegion`, { nomRegion, langue, idTypeActivite });
    setAlertCreer(true);
  }

  const accordion = async (id) => {
    setIdRegionSelectionner(id);
    setAlert(false);
  }

  const fermerCreer = async () => {
    setAlertCreer(false);
    setModalRegion(false);
    window.location.reload();
  }

  return (
    <>
      <div>
        <AppSidebar />
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <AppHeader />
          <div className="body flex-grow-1 px-3">
            <h4>Gestions des Régions</h4>

            <CButton color="success" style={{ marginBottom: '10px' }} onClick={() => setModalRegion(true)}>Créer</CButton>
            <br></br>
            <CAccordion flush>
              {region.map(regions => (
                <CAccordionItem key={regions.id} onClick={() => accordion(regions.id)}>
                  <CAccordionHeader>{regions.nomRegion} - {regions.type}</CAccordionHeader>
                  <CAccordionBody>
                    <CAlert visible={alert} color="success">
                      La région a bien été modifiée
                    </CAlert>
                    <CForm>
                      <CRow className="mb-3">
                        <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Nom de la région</CFormLabel>
                        <CCol sm={10} >
                          <CFormInput id="inputEmail3" value={region.find(laRegion => laRegion.id === regions.id)?.nomRegion} onChange={(e) => changerNom(regions.id, e.target.value)} />
                        </CCol>
                      </CRow>
                      <CFormLabel>Choisir la langue de cette région</CFormLabel>
                      <CFormSelect aria-label="Default select example" value={region.find(laRegion => laRegion.id === regions.id)?.langue} onChange={(e) => changerLangue(regions.id, e)}>
                        <option value="Français">Français</option>
                        <option value="Anglais">Anglais</option>
                      </CFormSelect>
                      <br></br>
                      <CFormLabel>Choisir le type activité de la région</CFormLabel>
                      <CFormSelect aria-label="Default select example" onChange={(e) => changeTypeActivite(regions.id, e)}>
                        {typeActivite.map(type => {
                          return (
                            <option key={type.id} value={type.id}>
                              {type.type}
                            </option>
                          )
                        })}
                      </CFormSelect>
                      <br />
                      <CButton type="submit" onClick={(e) => changerRegion(e)}>Modifier</CButton>
                      <CButton color="danger" style={{ marginLeft: '10px' }} onClick={() => setModalSupprimer(true)}>Supprimer</CButton>
                    </CForm>
                  </CAccordionBody>
                </CAccordionItem>
              ))}

            </CAccordion>

            <CModal visible={modalRegion}>
              <CModalTitle style={{ padding: '10px' }}>Créer une nouvelle région</CModalTitle>
              <CModalBody>
                <CFormLabel>Nom de la région</CFormLabel>
                <CFormInput
                  onChange={(e) => setNouvelleRegion(prevState => ({ ...prevState, nomRegion: e.target.value }))}
                  required
                ></CFormInput>

                <br />
                <CFormLabel>Langue de la région</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  onChange={(e) =>
                    setNouvelleRegion((prevState) => ({
                      ...prevState,
                      langue: e.target.value,
                    }))
                  }
                >
                  <option value="" defaultValue>-- Sélectionner une langue --</option>
                  <option value="Français">Français</option>
                  <option value="Anglais">Anglais</option>
                </CFormSelect>
                <br></br>
                <CFormSelect aria-label="Default select example" onChange={(e) => setNouvelleRegion(prevState => ({ ...prevState, idTypeActivite: e.target.value }))}>
                  <option value="" defaultValue>-- Sélectionner un type d'activité --</option>
                  {typeActivite.map(type => {
                    return (
                      <option key={type.id} value={type.id}>
                        {type.type}
                      </option>
                    )
                  })}
                </CFormSelect>
                <br></br>
                <CAlert visible={alertCreer} color="success">La région a été créée</CAlert>
              </CModalBody>
              <CModalFooter>
                <CButton onClick={fermerCreer}>
                  Fermer
                </CButton>
                <CButton
                  color="success"
                  onClick={creerRegion}
                >
                  Créer
                </CButton>
              </CModalFooter>
            </CModal>

            <CModal visible={modalSupprimer}>
              <CModalTitle style={{ padding: '10px' }}>Supprimer la région</CModalTitle>
              <CModalBody>Êtes-vous vraiment sûr de vouloir supprimer ?</CModalBody>
              <CModalFooter>
                <CButton
                  onClick={() => setModalSupprimer(false)}
                >
                  Fermer
                </CButton>
                <CButton
                  color="danger"
                  onClick={() =>
                    supprimer(
                      idRegionSelectionner
                    )
                  }
                >
                  Supprimer
                </CButton>
              </CModalFooter>
            </CModal>

          </div>
          <AppFooter />
        </div>
      </div>
    </>
  )
}

export default Authentification(Region, [3]);