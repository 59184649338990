import '../../../App';
import React, { Component } from "react";
import Chat from './chat';
import Authentification from 'src/hooks/Authentification'

class TableauDeBord extends Component {
  state = {
    idChatSelectionne: 0
  }
  ChangerId = (id) => 
  {
    this.setState({ idChatSelectionne: id })
    console.log("chatid ", this.state.idChatSelectionne)
  }
  render() {
    return (
      <React.Fragment>
              <Chat />
      </React.Fragment>
    );
  }
}

export default Authentification(TableauDeBord, [2]);
