import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'src/hooks/axios';
import css from './InfoParent.module.css'

export default function InfoParent({fermerModal, idUtilisateurParent}){

    const id = idUtilisateurParent;

    const[nom, setNom] = useState('');
    const[prenom, setPrenom] = useState('');
    const[dateNaissance, setDatNaissance] = useState('');
    const[detail, setDetail] = useState('');

    useEffect( () => {
        const fetch = async () =>{
        const res = await axiosInstance.get(`/informationParent/${id}`);
    
        setNom(res.data[0].nom);
        setPrenom(res.data[0].prenom);
        setDatNaissance(res.data[0].dateNaissance);
        setDetail(res.data[0].detail);
        }

        fetch();
    }, [id])
   
       

    return (
        <div className={css.modal}>
          <div className={css.modalContent}>
          <div className={css.modalTitle}>
          <h2>{prenom} {nom}</h2>
          <span className={css.close} onClick={fermerModal}>Fermer</span>
        </div>
            <div className={css.modalSeparator}></div>
            <h4>Date de naissance</h4>
            <p>{new Date(dateNaissance).toLocaleDateString()}{' '}</p>
            <h4>Détails</h4>
            <p>{detail}</p>
          </div>
        </div>
      );
}

InfoParent.propTypes = {
    fermerModal: PropTypes.func.isRequired,
    idUtilisateurParent: PropTypes.func.isRequired
  };