/*
 nom du fichier : Profile.js
 fonction: ce fichier represente le profil du parent qu'il peut modifier 

 auteur : maxence mazeau.

*/


import React, { useState, useEffect } from 'react'
import './Profile.css'
import DOMPurify from 'dompurify';
import Authentification from 'src/hooks/Authentification'
import axiosInstance from 'src/hooks/axios';

import {
  CAlert,
} from '@coreui/react'

function Profile() {

  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
  
  const [nom, setNom] = useState('')
  const [prenom, setPrenom] = useState('')
  const [courriel, setCourriel] = useState('')
  const [detail, setDetail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [dateNaissance, setDateNaissance] = useState('')
  const [nouveauMotDePasse, setNouveauMotDePasse] = useState('')
  const [nouveauMotDePasseConfirmer, setNouveauMotDePasseConfirmer] = useState('')
  const [erreur, setErreur] = useState('');

  const [detailExistant, setDetailExistant] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState(false);

  const idRole = utilisateur.idRole;
  if (idRole !== 1) {
    window.location.href = '/Error404';
  }

  let id = utilisateur.id

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get(`/utilisateur/${id}`);
      setNom(res.data[0].nom || '')
    setPrenom(res.data[0].prenom || '')
    setCourriel(res.data[0].courriel || '')
    setTelephone(res.data[0].telephone || '')
    setDateNaissance(res.data[0].dateNaissance || '')
    }

    fetch()

    axiosInstance.get(`/checkDetail/${id}`).then((response) => {
      if (response.data.exists) {
        setDetailExistant(true)
        setDetail(response.data.detail)
      }
    })

  }, [id])

  const changementProfil = async(e) => {
    e.preventDefault()


    await axiosInstance.put(`/changementProfil/${id}`, {
      nom,
      prenom,
      courriel,
      telephone,
      dateNaissance,
    }).then(setConfirmation(true));

   
  }

  const changementDetail = () => {
    if (detailExistant === true) {
      axiosInstance.put(`/changementDetail/${id}`, { detail }).then(setConfirmationInfo(true));
    } else {
      axiosInstance.post(`/envoieDetail`, { detail, idUtilisateur: id }).then(setConfirmationInfo(true));
    }
  }


  async function hashPassword(nouveauMotDePasseConfirmer) {
    const encoder = new TextEncoder();
    const data = encoder.encode(nouveauMotDePasseConfirmer);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hash = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
    return hash;
  }

  const changementMotDePasse = async() => {
    if (nouveauMotDePasse === nouveauMotDePasseConfirmer) {

      const motDePasseHasher = await hashPassword(nouveauMotDePasseConfirmer);
      
      await axiosInstance.put(`/changementMotDePasse/${id}`, {
        motDePasse: motDePasseHasher,
      }).then(alert('Votre mot de passe a été modifié'))
      setErreur('');
    } else {
      setErreur('Les mots de passe ne correspondent pas.')
    }
  }

  return (
    <div className="grid-container">
      <div className="grid-left">
        <form onSubmit={changementProfil}>
          <h1>Mes informations</h1>
          <CAlert visible={confirmation} color="success">Les modifications ont été enregistrées</CAlert>
          <div className="input-container">
            <label htmlFor="nom">Nom :</label>
            <div>
              <input id="nom" type="text" value={nom} onChange={(e) => setNom(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="prenom">Prénom : </label>
            <div>
              <input id="prenom" type="text" value={prenom} onChange={(e) => setPrenom(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="courriel">Courriel : </label>
            <div>
              <input id="courriel" type="email" value={courriel} onChange={(e) => setCourriel(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="courriel">Téléphone : </label>
            <div>
              <input id="courriel" type="tel" value={telephone} onChange={(e) => setTelephone(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="date">
              Date de naissance : {new Date(dateNaissance).toLocaleDateString()}{' '}
            </label>
            <div>
              <input id="date" type="date" onChange={(e) => setDateNaissance(DOMPurify.sanitize(e.target.value))} />
            </div>
          </div>
          <div className="input-container">
            <button type="submit">Modifier</button>
          </div>
        </form>
      </div>
      <div className="grid-right-top">
        <h2>Mon expérience d'allaitement</h2>
        <CAlert visible={confirmationInfo} color="success">Les modifications ont été enregistrées</CAlert>
        <div className="input-container">
          <label htmlFor="detail">
            Informations supplémentaire sur votre bébé et durée d'allaitement
          </label>
          <textarea
            type="text"
            id="detail"
            rows="8"
            value={detail}
            onChange={(e) => setDetail(DOMPurify.sanitize(e.target.value))}
          />
          <button type="submit" onClick={changementDetail}>
            Modifier
          </button>
          <p>Ces données seront visibles par nos bénévoles afin de mieux vous assister</p>
        </div>
      </div>
      <div className="grid-right-bottom">
        <h3>Changer mon mot de passe</h3>
        <div className="input-container">
          <label>Nouveau mot de passe :</label>
          <div>
            <p style={{color : 'red'}}>{erreur}</p>
            <input
              id="motDePasse"
              type="password"
              value={nouveauMotDePasse}
              onChange={(e) => setNouveauMotDePasse(DOMPurify.sanitize(e.target.value))}
            />
          </div>
        </div>
        <div className="input-container">
          <label htmlFor="motDePasseConfirm">Confirmer le nouveau mot de passe :</label>
          <div>
            <input
              id="motDePasseConfirm"
              type="password"
              value={nouveauMotDePasseConfirmer}
              onChange={(e) => setNouveauMotDePasseConfirmer(DOMPurify.sanitize(e.target.value))}
            />
            <button onClick={changementMotDePasse}>Modifier</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Authentification(Profile, [1]);
