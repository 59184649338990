import './App.css';
import Navbar from './components/interfaces/Navbar';
import React, { Component} from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InscriptionParent from './components/Parent/Inscription/InscriptionParent';
import InscriptionBenevole from './components/Benevole/Inscription/InscriptionBenevole';
import Connexion from '../src/components/Connexion/Connexion';
import Footer from './components/interfaces/Footer';
import ActivitePrenatale from './components/Activite/Prenatale/ActivitePrenatale';
import ActiviteHalteAllaitement from './components/Activite/HalteAllaitement/ActiviteHalteAllaitement';
import ActiviteHalteAllaitementAnglais from './components/Activite/HalteAllaitementAnglais/ActiviteHalteAllaitementAnglais';
import TableauDeBordChatBenevole from './components/Benevole/chat/TableauDeBordChatBenevole';
import Chat from './components/ChatParent/Chat';
import Profile from './components/Parent/Inscription/Profile'
import ProfilBenevole from './components/Benevole/profil/ProfilBenevole';
import TableauAdmin from './views/dashboard/TableauAdmin';
import ModificationPage from './views/dashboard/ModificationPage';
import ActiviteAdmin from './views/dashboard/ActiviteAdmin';
import Region from './views/dashboard/Region';
import RecuperationMdp from '../src/components/RecuperationMdp/RecuperationMdp';
import MotDePasseOublie from './components/MotDePasseOublie/MotDePasseOublie';
import ModificationCourriel from './views/dashboard/ModificationCourriel';
import ModificationDocumentation from './views/dashboard/ModificationDocumentation';
import DocumentationBenevole from './views/dashboard/DocumentationBenevole';
import DocumentationParent from './views/dashboard/DocumentationParent';
import InformationParent from './components/Documentation/InformationParent';
import InformationBenevole from './components/Documentation/InformationBenevole';
import Error404 from './components/RecuperationMdp/Error404';
import InscriptionActivite from './views/dashboard/Inscription/InscriptionActivite';

class App extends Component {
  render() {
    const hideNavBarPaths = ['/TableauDeBordAdmin', '/ActiviteAdmin', '/Region', '/ModificationPage', '/ModificationCourriel',
    '/ModificationDocumentation', '/InscriptionActivite', '/DateActivite', '/ListeInscrit', '/RecuperationMdp/:uuid'];
    const shouldHideNavBar = hideNavBarPaths.some(path => {
      if (path.includes(':')) {
        const basePath = path.split(':')[0];
        return window.location.pathname.includes(basePath);
      } else {
        return window.location.pathname === path;
      }
    });

    return (
      <div className="">
        {shouldHideNavBar ? null : <Navbar />} 
     <div >
       <Router>
            <Routes> 
             <Route path="/InscriptionParent" element={<InscriptionParent />}></Route> 
             <Route path="/InscriptionBenevole" element={<InscriptionBenevole />}></Route>
             <Route path="/Error404" element={<Error404 />} />
             <Route path="/" element={<Connexion />}></Route>
             <Route path="/Connexion" element={<Connexion />}></Route> 
             <Route path="/RecuperationMdp/:uuid" element={<RecuperationMdp />} />
             <Route path="/ActivitePrenatale" element={<ActivitePrenatale />}></Route> 
             <Route path="/ActiviteHalteAllaitement" element={<ActiviteHalteAllaitement />}></Route> 
             <Route path="/ActiviteHalteAllaitementAnglais" element={<ActiviteHalteAllaitementAnglais />}></Route> 
             <Route path="/Region" element={<Region />}></Route>
              
              <Route path="/TableauDeBordChatBenevole" element={<TableauDeBordChatBenevole />}></Route> 
              <Route path="/Chat" element={<Chat />}></Route> 
              <Route path="/Profile" element={<Profile />}></Route>
              <Route path="/ProfilBenevole" element={<ProfilBenevole />}></Route>
              <Route path="/TableauDeBordAdmin" element={<TableauAdmin />}></Route>
              <Route path="/ModificationPage" element={<ModificationPage />}></Route>
              <Route path="/ActiviteAdmin" element={<ActiviteAdmin />}></Route>
              <Route path="/MotDePasseOublie" element={<MotDePasseOublie />}></Route>
              <Route path="/ModificationCourriel" element={<ModificationCourriel />}></Route>
              <Route path="/ModificationPage" element={<ModificationPage />}></Route> 
              <Route path="/ModificationDocumentation" element={<ModificationDocumentation />}></Route>
              <Route path="/DocumentationBenevole" element={<DocumentationBenevole />}></Route>   
              <Route path="/DocumentationParent" element={<DocumentationParent />}></Route>  
              <Route path="/InformationParent" element={<InformationParent />}></Route>    
              <Route path="/InformationBenevole" element={<InformationBenevole />}></Route>       
              <Route path="/ActiviteAdmin" element={<ActiviteAdmin />}></Route>
              <Route path="/InscriptionActivite" element={<InscriptionActivite />}></Route>   
              <Route path="*" element={<Error404 />}/>
           </Routes> 
       </Router>
     </div>
     {shouldHideNavBar ? null : <Footer />}
   </div>
   
    )
  }
}
export default App;

