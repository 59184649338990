import React, { useEffect, useState } from 'react';
import '../../scss/style.scss'
import CourrielMotDePasse from './CourrielActivite';
import CourrielActivite from './CourrielMotDePasse';
import CourrielInscription from './CourrielInscription';

import {
  CButton,
  CButtonGroup,
} from '@coreui/react'

import { AppFooter, AppHeader, AppSidebar } from 'src/components/Administrateur'
import Authentification from 'src/hooks/Authentification'

function ModificationCourriel(){
    const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
    const idRole = utilisateur ? utilisateur.idRole : null;
    if (idRole !== 3) {
  
        window.location.href = '/Error404';
      }
    const[pageMotDePasse, setPageMotDePasse] = useState(false);
    const[pageInscription, setPageInscription] = useState(false);
    const[pageActivite, setPageActivite] = useState(false);

    useEffect(() => {
        setPageMotDePasse(true);
    }, [])

    const PageMotDePasse = () => {
        setPageMotDePasse(true);
        setPageInscription(false);
        setPageActivite(false);
    }

    const PageInscription = () => {
        setPageMotDePasse(false);
        setPageInscription(true);
        setPageActivite(false);
    }

    const PageActivite = () => {
        if(PageActivite !== true){
            setPageActivite(true);
        } else {
            setPageActivite(false);
        }
        setPageMotDePasse(false);
        setPageInscription(false);
        
    }
    

    
    return(
        <>
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100 bg-light">
                <AppHeader/>
            <div className="body flex-grow-1 px-3">
                
            <CButtonGroup role="group" aria-label="Basic mixed styles example">
                <CButton color="primary" onClick={PageActivite}>Mot de passe</CButton>
                <CButton color="secondary" onClick={PageMotDePasse}>Activités</CButton>
                <CButton color="warning" onClick={PageInscription}>Inscription</CButton>
            </CButtonGroup>

            {pageMotDePasse && <CourrielMotDePasse />}
            {pageInscription && <CourrielInscription />}
            {pageActivite && <CourrielActivite />}
            </div>
            </div>
            <AppFooter />
        </div>
        </>
    )
}

export default Authentification(ModificationCourriel, [3]);