import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilHouse, cilColorBorder,cilMap,cilPushchair, cilEnvelopeClosed, cilDescription } from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Usagers',
    to: '/TableauDeBordAdmin',
    icon: <CIcon icon={cilHouse} customClassName="nav-icon" />,
    badge: {
      color: 'info',
    },
  },
  {
    component: CNavTitle,
    name: 'Gestion',
    to : '/TableauDeBordAdmin'
  },
  {
    component: CNavItem,
    name: 'Pages',
    to: '/ModificationPage',
    icon: <CIcon icon={cilColorBorder} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Activités',
    to: '/ActiviteAdmin',
    icon: <CIcon icon={cilPushchair} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Région',
    to: '/Region',
    icon: <CIcon icon={cilMap} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Courriel',
    to: '/ModificationCourriel',
    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Documentation',
    to: '/ModificationDocumentation',
    icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
  },
  {
    component : CNavItem,
    name : 'Inscription',
    to :'/Inscriptionactivite',
    icon: <CIcon icon={cilPushchair} customClassName="nav-icon" />,
  }]

export default _nav
