/*
 Nom du fichier : InscriptionParent.js
 fonction: ce fichier représente la page d'inscription du parent, avec le formulaire ainsi que les textes.

 auteur : Thanina adda.
 Modification : Maxence Mazeau au niveau du name de password pour motDePasse, 
 courriel a la place de mail pour que ce soit fonctionnel avec la nouvelle Bd.

 Modification_v2: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/

// les imports 
import './InscriptionParent.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import DOMPurify from 'dompurify';

import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'

//Fonction Inscription Parent.
function InscriptionParent() {

  function MonLienInscriptionBenevole() {
    window.location.href = '/InscriptionBenevole'
  }
  const [pages, setPages] = useState([]);
  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [courriel, setCourriel] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const [erreur, setErreur] = useState('');
  const [erreurMDP, setErreurMDP] = useState('');


  useEffect(() => {
    const getPages = async () => {
      
        const res = await axios.get(`https://api.chat.allaitementquebec.org/pagesInterfaces/2`);
        setPages(res.data);
        const response = await axios.get(`https://api.chat.allaitementquebec.org/courrielInscription`);
        setTitre(response.data[0].titre);
        setDescription(response.data[0].description);
    };
    getPages();
  }, []);

  useEffect(() => {
    const motDePasseInput = document.getElementById('motDePasse');

    motDePasseInput.addEventListener('input', function() {
      if (motDePasseInput.validity.tooShort) {
        setErreurMDP(true)
      } else {
        setErreurMDP(false);
      }
    });
  }, []);

  async function hashPassword(motDePasse) {
    const encoder = new TextEncoder();
    const data = encoder.encode(motDePasse);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hash = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
    return hash;
  }

  const envoiConfirmationInscription = async (e) => {
    e.preventDefault();

    const hashedPassword = await hashPassword(motDePasse);
    
    await axios.post(`https://api.chat.allaitementquebec.org/creerParent`, { nom, prenom, courriel, motDePasse : hashedPassword })
    .then(res => {
      if(motDePasse.length > 6) {
        if(res.data.emailExists === false) {
          axios.post('https://api.chat.allaitementquebec.org/inscription', {
           to: courriel,
           subject: titre,
           body: description
           });
           setErreur("");
         alert(`Une confirmation d'inscription a été envoyer. Regardez vous spams`);
         window.location.href = '/Connexion';
       } else {
         setErreur("Le courriel existe déjà");
       }
     } else {
      setErreurMDP('Le mot de passe doit faire 6 caractères minimum');
     }
      }); 
    
  }

  return (
    <div className="mainInscriptionParent">
      <div className="form-areaInscriptionParent row ">
        <div className="col-12">
          <h3 className='titreInterfaceParent'>
            <FontAwesomeIcon icon={faCaretLeft} />
            Inscription parent
          </h3>
          <div className="row g-0 bg-transparent d-flex ">
            <div className="col-lg-5 single-form ">
              <div className="rightInscriptionParent">
                <form method="post">
                  <p style={{color : 'red'}}>{erreur}</p>
                  <div className="row g-0" style={{ paddingBottom: '10px' }}>
                    <div className="col" style={{ marginRight: '5px' }}>
                      <input type="text" name="nom" className="input form-control" placeholder="Nom" onChange={(e) => setNom(DOMPurify.sanitize(e.target.value))} required />
                    </div>

                    <div className="col" style={{ marginLeft: '5px' }}>
                      <input type="text" name="prenom" className="input form-control" placeholder="Prénom" onChange={(e) => setPrenom(DOMPurify.sanitize(e.target.value))} required />
                    </div>
                  </div>

                  <div className="mb-3">
                    <input type="email" name="courriel" className="input form-control" placeholder="Courriel" onChange={(e) => setCourriel(DOMPurify.sanitize(e.target.value))} required />
                  </div>

                  <div className="mb-3">
                    <input type="password" id="motDePasse" name="motDePasse" className="input form-control" placeholder="Mot de passe" minLength="6" onChange={(e) => setMotDePasse(DOMPurify.sanitize(e.target.value))} required />
                    <p id="motDePasseErreur" style={{color: 'red'}}>{erreurMDP}</p>
                  </div>
                  <div className="btnSubmit">
                    <button type="submit" className="btn btn-primary rounded" onClick={envoiConfirmationInscription}>
                      Inscription
                    </button>
                  </div>
                </form>
                <div className="spanFomulaireParent">
                  <span onClick={MonLienInscriptionBenevole} >Inscrivez-vous pour pouvoir clavarder avec nos bénévoles.</span>
                </div>
              </div>
            </div>
            <div className=" col-lg-5">
              <div className="leftInscriptionParent">
                {pages.map(page =>
                  <React.Fragment key={page.id}>
                    <h3>{page.titre}</h3>
                    <div className="texteLeftInscriptionParent">
                      <div>
                        <span className="spanTexte">
                          {page.description}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InscriptionParent
