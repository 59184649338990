import React, { useState, useEffect } from 'react';
import axiosInstance from 'src/hooks/axios';

import PropTypes from 'prop-types';
import {
    CButton,
    CFormSelect,
    CFormInput,
    CFormLabel,
    CAlert
  } from '@coreui/react'

export default function DateAdmin({setIdActiviteSelectionner}){
   
    const [dateActivite, setDateActivite] = useState([]);

    const [modalDate, setModalDate] = useState(false);

    const [nouvelleDate, setNouvelleDate] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertSupprimer, setAlertSupprimer] = useState(false);
    const [nouveauLienZoom, setNouveauLienZoom] = useState('');
    const [updateCounter, setUpdateCounter] = useState(0);
    const [idDateSelectionner, setIdDateSelectionner] = useState('');

    useEffect(() => {
        const fetch = async() => {
            await axiosInstance.get(`/dateActivite/${setIdActiviteSelectionner}`).then(res => {
          setDateActivite(res.data);
        })
        }
        fetch();
    }, [setIdActiviteSelectionner, updateCounter, idDateSelectionner])

    const dateSelectionner = async(e) => {
        setIdDateSelectionner(e);
        setAlert(false);
    }

    const modifierDate = async(e) => {
        setModalDate(false);
        const dateObject = new Date(Date.parse(nouvelleDate));
        if (isNaN(dateObject.getTime())) {
          return;
        }
        const formattedDate = dateObject.toISOString().split('T')[0];
        await axiosInstance.put(`/modifierDate/${idDateSelectionner}`, { date: formattedDate, lienZoom : nouveauLienZoom })
        setAlert(true)
        setUpdateCounter(updateCounter + 1);
      }
      

    const supprimer = async() => {
        await axiosInstance.delete(`/supprimerDateActivite/${idDateSelectionner}`);
        setAlertSupprimer(true)
    }


    return (

        <div>  
            <CAlert visible={alert} color="success">La date a été modifiée</CAlert>
            <CAlert visible={alertSupprimer} color="success">La date a été supprimée</CAlert>
        <CFormLabel>Dates et lien zoom de l'activité</CFormLabel>
            <CFormSelect aria-label="Default select example" onChange={(e) => dateSelectionner(e.target.value)}>
            <option defaultValue="">
                     --Sélectionnez une date--
                    </option>
                {dateActivite.map(dates => {
                     
                    return (
                        <option  key={dates.id} value={dates.id}>
                            {new Date(dates.date).toLocaleDateString()} - {dates.lienZoom}
                        </option>
                        )
                        })}
            </CFormSelect>
            <br></br>
                {modalDate === true ? 
                <div>
                <CFormInput type="date" onChange={(e) => setNouvelleDate(e.target.value)}/>
                <br></br>
                <CFormInput type="text" placeholder="Lien zoom (si besoin)" onChange={(e) => setNouveauLienZoom(e.target.value)}/>
                <br></br>
                <CButton type="submit" color="success"
                onClick={(e) => modifierDate(e)}>Définir</CButton>
                 <CButton color="danger" style={{ marginLeft : '10px' }} onClick={() => setModalDate(false)}>Annuler</CButton>
                <br></br>
                </div>
                : 
                <div>
                    <CButton type="submit" onClick={(e) => setModalDate(true)}>Modifier</CButton>
                
                <CButton color="danger" style={{ marginLeft : '10px' }} onClick={() => supprimer(dateActivite.id)}>Supprimer</CButton>
                </div>
                }
            <br></br>
</div>
    )
}

DateAdmin.propTypes = {
    setIdActiviteSelectionner: PropTypes.number.isRequired,
}