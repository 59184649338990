/*
 nom du fichier : Connexion.js
 fonction: ce fichier represente la connexion des utilisateurs

 auteur : maxence mazeau.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css

*/
import './Connexion.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import DOMPurify from 'dompurify';

import axios from 'axios'
import React, { useState,useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'

function Connexion() {
  function MonLienInscriptionParent() {
    window.location.href = '/InscriptionParent'
  }

  function MonLienMotDePasseOublie() {
    window.location.href = '/MotDePasseOublie'
  }
  const inputCourriel = useRef()
  const inputMotDePasse = useRef()
  const [pages, setPages] = useState([]);
  const [erreur, setErreur] = useState('');
  const [user, setUser] = useState(null);

  async function hashPassword(motDePasse) {
    const encoder = new TextEncoder();
    const data = encoder.encode(motDePasse);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hash = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
    return hash;
  }

  const Connecter = async(e) => {
    e.preventDefault()
    const valeurCourriel = inputCourriel.current.value
    const valeurMotDePasse = inputMotDePasse.current.value
    const courriel = DOMPurify.sanitize(valeurCourriel);
    const motDePasse = DOMPurify.sanitize(valeurMotDePasse);

    const hashedPassword = await hashPassword(motDePasse);

    await axios.post('https://api.chat.allaitementquebec.org/connexion', { courriel, motDePasse : hashedPassword }).then((response) => {
      sessionStorage.setItem('utilisateur', JSON.stringify(response.data))
      setUser(response.data);
    })
    .catch((error) => {
      
      setErreur("L'adresse courriel ou le mot de passe est incorrect"); // Modifier l'état d'erreur en cas d'erreur Axios
    });
  
   
    }

    useEffect(() => {
      if (user) {
        const id = user.id;
        const idRole = user.idRole;
        switch (idRole) {
          case 1:
            // Redirige vers la page pour l'idRole 1
            window.location.href = '/Chat';
            break;
          case 2:
            // Redirige vers la page pour l'idRole 2
            axios.get(`https://api.chat.allaitementquebec.org/infoBenevole/${id}`).then(res => {
              if(res.data[0].statutCompte === 0){
                sessionStorage.clear();
                alert("Votre compte n'est pas encore actif");
              } else {
                window.location.href = '/TableauDeBordChatBenevole';
              }
            })
            break;
          case 3:
            // Redirige vers la page pour l'idRole 3
            window.location.href = '/TableauDeBordAdmin';
            break;
        }
      }
    }, [user]); // Dependency on the user state

  useEffect(() => {
    const getPages = async () => {
        const res = await axios.get(`https://api.chat.allaitementquebec.org/pagesInterfaces/1`);
        setPages(res.data);
       
    };
    getPages();
  }, []);


  return (
    <div className="mainConnexion">
      <div className="form-area row col-12 ">
        <div className="col-12">
        <h3 className='titreInterfaceConnexion'>  <FontAwesomeIcon icon={faCaretLeft} />Connexion utilisateur</h3>
          <div className="row g-0 bg-transparent d-flex ">
            <div className="col-lg-5 single-form ">
              <div className="right">
                <form action="post" onSubmit={Connecter}>
                  <div className="mb-3">
                    <p style={{color : 'red'}}>{erreur}</p>
                    
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Courriel:
                    </label>
                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" ref={inputCourriel} required />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputPassword1" className="form-label">
                      Mot de passe:
                    </label>

                    <input type="password" className="form-control" id="exampleInputPassword1" aria-describedby="passwordHelp" ref={inputMotDePasse} required />
                  </div>
                  <span onClick={MonLienMotDePasseOublie} style={{cursor:'pointer'}}>Mot de passe oublié cliquez ici.</span>
                  <div className="btnSubmit">
                    <button type="submit" className="btn btn-primary ">
                      Connexion
                    </button>
                  </div>
                </form>
                <div className="spanFomulaireConnexion">
                  <span onClick={MonLienInscriptionParent} >Inscrivez-vous pour pouvoir clavarder avec nos bénévoles.</span>
              
                </div>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="left">
                {pages.map(page =>
                  <React.Fragment key={page.id}>
                    <h3>{page.titre}</h3>
                    <div className="texteLeft">
                      <div>
                        <span className="spanTexte">
                          {page.description}
                          
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Connexion
