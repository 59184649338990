import React from 'react';
import PropTypes from 'prop-types';
import css from '../Benevole/chat/InfoParent.module.css'

export default function InfoActivite({fermerModalActivite, idDateActivite, activiteSelectionner}){
   
    return (
        <div className={css.modal}>
          <div className={css.modalContent}>
          <div className={css.modalTitle}>
          <h2>{activiteSelectionner.nomEvent}</h2>
          <span className={css.close} onClick={fermerModalActivite}>Fermer</span>
        </div>
            <div className={css.modalSeparator}></div>
            <h4>Description :</h4>
                <p>{activiteSelectionner.description}</p>
            <h4>Date de l'activité :</h4>
                <p>Le {new Date(activiteSelectionner.date).toLocaleDateString()} de {activiteSelectionner.horaire}</p>
            <h4>Lieu :</h4>
                <p>{activiteSelectionner.lieu}</p>
            <h4>Tarif :</h4>
                <p>{activiteSelectionner.tarif}</p>
                {activiteSelectionner.lienZoom != null && activiteSelectionner.lienZoom.trim() !== '' && 
    <>
        <h4>Lien Zoom :</h4>
        <p>{activiteSelectionner.lienZoom}</p> 
    </>
}
        </div>
        </div>
      );
}

InfoActivite.propTypes = {
    fermerModalActivite: PropTypes.func.isRequired,
    idDateActivite: PropTypes.func.isRequired,
    activiteSelectionner: PropTypes.func.isRequired,
    
  };