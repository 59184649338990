import React, { Component } from 'react'
import axiosInstance from 'src/hooks/axios';
import '../../scss/style.scss'


import {
  CCard,
  CButton,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CModal,
  CModalFooter,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CAlert
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

import { cilPeople } from '@coreui/icons'

import { AppFooter, AppHeader, AppSidebar } from 'src/components/Administrateur'
import Authentification from 'src/hooks/Authentification'
class ModificationPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageInscription: [],
      pageParent: [],
      pageConnexion: [],
      modifierPageInscription: false,
      modifierPageParent: false,
      modifierPageConnexion: false,
      selectedPage: null,
      valeur: '',
      alert: false
    }
  }
 
  handleChange(event) {
    const { name, value } = event.target
    this.setState((prevState) => ({
      selectedPage: { ...prevState.selectedPage, [name]: value },
    }))
  }

  selectPageInscriptionMod = (item) => {
    this.setState({ selectedPage: item, modifierPageInscription: true })
  }

  selectPageParentMod = (item) => {
    this.setState({ selectedPage: item, modifierPageParent: true })
  }
  
  selectPageConnexionMod = (item) => {
    this.setState({ selectedPage: item, modifierPageConnexion: true })
  }
  
  modifierTexte = async(id, titre,description,zone) => {
    const data = {
      titre,
      description,
      zone,
    }
    axiosInstance
      .put(`/modifierTexte/${id}`, data)
      .then((response) => response.data)

    this.setState({ alert :true})
  }

  fermerModifier = async() => {
    this.setState({modifierPageConnexion : false})
    this.setState({alert : false});
    window.location.reload();
  }

  componentDidMount() {
    axiosInstance.get('/pageInscription').then((resInsription) => {
      this.setState({
        pageInscription: resInsription.data,
      })
    })
    axiosInstance.get('/pageParent').then((resParent) => {
      this.setState({
        pageParent: resParent.data,
      })
    })
    axiosInstance.get('/pageConnexion').then((resConnexion) => {
      this.setState({
        pageConnexion: resConnexion.data,
      })
    })
  }

  render() {
    const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
    const idRole = utilisateur ? utilisateur.idRole : null;
    if (idRole !== 3) {
  
      window.location.href = '/Error404';
    }
    const { pageInscription } = this.state
    const { pageParent } = this.state
    const { pageConnexion } = this.state
    this.handleChange = this.handleChange.bind(this)

    return (
      <>
      <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader/>
      <div className="body flex-grow-1 px-3">
      <CRow>
          <CCol xs>
            <CCard className="mb-4">
              <CCardHeader> Page inscription bénévole</CCardHeader>
              <CCardBody>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell className="text-center">
                        <CIcon icon={cilPeople} />
                      </CTableHeaderCell>
                      <CTableHeaderCell>Titre</CTableHeaderCell>
                      <CTableHeaderCell>Description</CTableHeaderCell>
                      <CTableHeaderCell>Zone</CTableHeaderCell>
                      <CTableHeaderCell></CTableHeaderCell>
                      <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {pageInscription.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={item.Id}>
                        <CTableDataCell className="text-center">
                          <div></div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item.titre}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                        <div style={{textAlign:'justify'}}>{item.description}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item.zone}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="warning" onClick={() => this.selectPageInscriptionMod(item)}>
                            Modifier
                          </CButton>
                        </CTableDataCell>
                        <CModal visible={this.state.modifierPageInscription}>
                          <CModalHeader>
                            <CModalTitle>Modifier le texte </CModalTitle>
                          </CModalHeader>
                          <CModalBody>
                            <CFormLabel>Titre : </CFormLabel>
                            <CFormInput
                              name="titre"
                              value={this.state.selectedPage ? this.state.selectedPage.titre : ''}
                              onChange={this.handleChange}
                            ></CFormInput>
                            <CFormLabel>Description : </CFormLabel>
                            <CFormTextarea
                              name="description"
                              value={this.state.selectedPage ? this.state.selectedPage.description : ''}
                              onChange={this.handleChange}
                            ></CFormTextarea>
                            <CFormLabel>Zone: </CFormLabel>
                            <CFormInput  disabled={true}
                              name="zone"
                              value={
                                this.state.selectedPage ? this.state.selectedPage.zone : ''}
                              onChange={this.handleChange}
                            ></CFormInput>
                            <br />
                            <CAlert visible={this.state.alert} color="success">Le texte a bien été modifié</CAlert>
                          </CModalBody>
                          <CModalFooter>
                            <CButton onClick={this.fermerModifier}>
                              Fermer
                            </CButton>
                            <CButton
                              color="success"
                              onClick={() =>
                                this.modifierTexte(
                                  this.state.selectedPage.Id,
                                  this.state.selectedPage.titre,
                                  this.state.selectedPage.description,
                                  this.state.selectedPage.zone,

                                )
                              }
                            >
                              Modifier
                            </CButton>
                          </CModalFooter>
                        </CModal>
                        <CTableDataCell>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs>
            <CCard className="mb-4">
              <CCardHeader>Page parent</CCardHeader>
              <CCardBody>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell className="text-center">
                        <CIcon icon={cilPeople} />
                      </CTableHeaderCell>
                      <CTableHeaderCell>Titre</CTableHeaderCell>
                      <CTableHeaderCell>Description</CTableHeaderCell>
                      <CTableHeaderCell>Zone</CTableHeaderCell>
                      <CTableHeaderCell></CTableHeaderCell>
                      <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {pageParent.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={item.id}>
                        <CTableDataCell className="text-center">
                          <div></div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item.titre}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                        <div style={{textAlign:'justify'}}>{item.description}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item.zone}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="warning" onClick={() => this.selectPageParentMod(item)}>
                            Modifier
                          </CButton>
                        </CTableDataCell>
                        <CModal visible={this.state.modifierPageParent}>
                          <CModalHeader>
                            <CModalTitle>Modifier le texte </CModalTitle>
                          </CModalHeader>
                          <CModalBody>
                            <CFormLabel>Titre : </CFormLabel>
                            <CFormInput
                              name="titre"
                              value={this.state.selectedPage ? this.state.selectedPage.titre : ''}
                              onChange={this.handleChange}
                            ></CFormInput>
                            <CFormLabel>Description : </CFormLabel>
                            <CFormTextarea
                              name="description"
                              value={this.state.selectedPage ? this.state.selectedPage.description : ''}
                              onChange={this.handleChange}
                            ></CFormTextarea>
                            <br />
                            <CFormLabel>Zone: </CFormLabel>
                            <CFormInput
                              name="zone"  disabled={true}
                              value={this.state.selectedPage ? this.state.selectedPage.zone : ''}
                              onChange={this.handleChange}
                            ></CFormInput>
                            <br />
                            <CAlert visible={this.state.alert} color="success">Le texte a bien été modifié</CAlert>
                          </CModalBody>
                          <CModalFooter>
                            <CButton onClick={this.fermerModifier}>
                              Fermer
                            </CButton>
                            <CButton
                              color="success"
                              onClick={() =>
                                this.modifierTexte(
                                  this.state.selectedPage.Id,
                                  this.state.selectedPage.titre,
                                  this.state.selectedPage.description,
                                  this.state.selectedPage.zone,
                                  
                                )
                              }
                            >
                              Modifier
                            </CButton>
                          </CModalFooter>
                        </CModal>
                        <CTableDataCell>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol xs>
            <CCard className="mb-4">
              <CCardHeader>Page connexion</CCardHeader>
              <CCardBody>
                <CTable align="middle" className="mb-0 border" hover responsive>
                  <CTableHead color="light">
                    <CTableRow>
                      <CTableHeaderCell className="text-center">
                        <CIcon icon={cilPeople} />
                      </CTableHeaderCell>
                      <CTableHeaderCell>Titre</CTableHeaderCell>
                      <CTableHeaderCell>Description</CTableHeaderCell>
                      <CTableHeaderCell>Zone</CTableHeaderCell>
                      <CTableHeaderCell></CTableHeaderCell>
                      <CTableHeaderCell></CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {pageConnexion.map((item, index) => (
                      <CTableRow v-for="item in tableItems" key={item.Id}>
                        <CTableDataCell className="text-center">
                          <div></div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item.titre}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                        <div style={{textAlign:'justify'}}>{item.description}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <div>{item.zone}</div>
                        </CTableDataCell>
                        <CTableDataCell>
                          <CButton color="warning" onClick={() => this.selectPageConnexionMod(item)}>
                            Modifier
                          </CButton>
                        </CTableDataCell>
                        <CModal visible={this.state.modifierPageConnexion}>
                          <CModalHeader>
                            <CModalTitle>Modifier le texte </CModalTitle>
                          </CModalHeader>
                          <CModalBody>
                            <CFormLabel>Titre : </CFormLabel>
                            <CFormInput
                              name="titre"
                              value={this.state.selectedPage ? this.state.selectedPage.titre : ''}
                              onChange={this.handleChange}
                            ></CFormInput>
                            <CFormLabel>Description : </CFormLabel>
                            <CFormTextarea
                              name="description"
                              value={this.state.selectedPage ? this.state.selectedPage.description : ''}
                              onChange={this.handleChange}
                            ></CFormTextarea>
                            <br />
                            <CFormLabel>Zone: </CFormLabel>
                            <CFormInput
                              disabled={true}
                              name="zone" 
                              value={this.state.selectedPage ? this.state.selectedPage.zone : ''}
                              onChange={this.handleChange}
                            ></CFormInput>
                            <br />
                            <CAlert visible={this.state.alert} color="success">Le texte a bien été modifié</CAlert>
                          </CModalBody>
                          <CModalFooter>
                            <CButton onClick={this.fermerModifier}>
                              Fermer
                            </CButton>
                            <CButton
                              color="success"
                              onClick={() =>
                                this.modifierTexte(
                                  this.state.selectedPage.Id,
                                  this.state.selectedPage.titre,
                                  this.state.selectedPage.description,
                                  this.state.selectedPage.zone
                                )
                              }
                            >
                              Modifier
                            </CButton>
                          </CModalFooter>
                        </CModal>
                        <CTableDataCell>
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        </div>
        <AppFooter/>
      </div>
    </div>
      </>
    )
  }
}

export default Authentification(ModificationPage, [3]);
