/*
 Nom du fichier : InscriptionParent.js
 fonction: ce fichier représente la page d'inscription du parent, avec le formulaire ainsi que les textes.

 auteur : Thanina adda.
 Modification : Maxence Mazeau au niveau du name de password pour motDePasse, 
 courriel a la place de mail pour que ce soit fonctionnel avec la nouvelle Bd.

 Modification_v2: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css
*/

// les imports 
import './Information.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import axiosInstance from 'src/hooks/axios';
import React, { useState, useEffect } from 'react'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import Authentification from 'src/hooks/Authentification'
//Fonction Inscription Parent.
function InformationBenevole() {

  const [documentation, setDocumentation] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get('/documentationBenevole');
      setDocumentation(res.data);
    }

    fetch();

  }, []);

  return (
    <div className="mainDocumentation">
      <div className="form-areaDocumentation">
        <div className=" Documentation col-12 ">
          <h3>
            <FontAwesomeIcon icon={faPaperclip} />
            <span>Plus d'informations sur l'allaitement:</span>
          </h3>
              {documentation.map(item => (
                <div key={item.id} className='info'>
                  <h4 className='titre'>{item.titre}</h4>
                  <p className='description'>{item.description}</p>
                  <a className='lien' href={item.lien}>{item.lien}</a>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}

export default Authentification(InformationBenevole, [2,3]);
