import './RecuperationMdp.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';

function RecuperationMdp() {
  const { uuid } = useParams();

  const [utilisateur, setUtilisateur] = useState([]);

  const[nouveauMotDePasse, setNouveauMotDePasse] = useState('');
  const[confirmerNouveauMotDePasse, setConfirmerNouveauMotDePasse] = useState('');
  const [erreur, setErreur] = useState('');

useEffect(() => {
  const checkToken = async () => {
    const response = await axios.get(`https://api.chat.allaitementquebec.org/checkToken/${uuid}`);
    if (response.data.valid) {
      setUtilisateur(response.data.results[0].idUtilisateur)
      
    } else {
      window.location.href = '/Error404';
    }
  };

  checkToken();
}, [uuid]);

  function MonLienInscriptionParent() {
    window.location.href = '/InscriptionParent';
  }

  const premierMotDePasse = (e) => {
    const valeurPurifier = DOMPurify.sanitize(e.target.value);
    setNouveauMotDePasse(valeurPurifier);
  }

  const confirmerMotDePasse = (e) => {
    const valeurPurifier = DOMPurify.sanitize(e.target.value);
    setConfirmerNouveauMotDePasse(valeurPurifier);
  }

  async function hashPassword(confirmerNouveauMotDePasse) {
    const encoder = new TextEncoder();
    const data = encoder.encode(confirmerNouveauMotDePasse);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hash = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
    return hash;
  }

  const changerMotDePasse = async(e) => {
    e.preventDefault();
      const response = await axios.get(`https://api.chat.allaitementquebec.org/checkToken/${uuid}`);
      if (response.data.valid) {
        if(nouveauMotDePasse === confirmerNouveauMotDePasse){

          const motDePasseHasher = await hashPassword(confirmerNouveauMotDePasse);
          
          await axios.put(`https://api.chat.allaitementquebec.org/changerMotDePasse/${utilisateur}`, { motDePasse : motDePasseHasher })
          .then(window.location.href = '/Connexion');
        } else {
          setErreur('Les deux mots de passes ne correspondent pas' );

        }
      } else {
        // Token is expired or not valid, show an error message or redirect the user
        window.location.href = '/Error404';
      }
  }



  if (!uuid) {
    window.location.href = '/Error404';
  }

  return (
    <div className="mainRecuperationMdp">
      <div className="form-areaRecuperationMdp row col-12">
        <div className="row g-0 bg-transparent d-flex ">
          <h3>
            {' '}
            <FontAwesomeIcon icon={faCaretLeft} />
            Modification du mot de passe
          </h3>
          <div className="col-lg-5 single-form">
            <div className="rightRecuperationMdp">
              <form >
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Nouveau mot de passe :
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    aria-describedby="passwordHelp"
                    onChange={(e) => premierMotDePasse(e)}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="exampleInputPassword2" className="form-label">
                    Confirmer mot de passe :
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    aria-describedby="passwordHelp"
                    onChange={(e) => confirmerMotDePasse(e)}
                    required
                  />
                 <p style={{ color : 'red'}}>{erreur}</p>
                </div>
                <div className="btnSubmitRecuperationMdp">
                  <button type="submit" className="btn btn-primary rounded"  onClick={(e) => changerMotDePasse(e)}>
                    Récupérer le compte
                  </button>
                </div>
              </form>
              <div className="spanFomulaireRecuperationMdp">
                <h4 onClick={MonLienInscriptionParent}>
                  Revenir sur le site
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecuperationMdp;
