import React from 'react';
import PropTypes from 'prop-types';
import axiosInstance from 'src/hooks/axios';
import css from '../Benevole/chat/InfoParent.module.css'

export default function InfoActivite({fermerModalSupprimer, idDateActivite, activiteSelectionner, idUtilisateur}){

   const supprimerActivite = () => {
     
    axiosInstance.delete(`/desinscrire`, {
        data: {
            idDateActivite: idDateActivite,
            idUtilisateur: idUtilisateur
            }
  
        })
        
        fermerModalSupprimer();
   }

    return (
        <div className={css.modal}>
          <div className={css.modalContent}>
          <div className={css.modalTitle}>
          <h4>Voulez-vous vraiment vous désinscrire ?</h4>
          <span className={css.close} onClick={fermerModalSupprimer}>Fermer</span>
        </div>
            <div className={css.modalSeparator}></div>
            <div className={css.buttonContainer}>
      <button className={`${css.button} ${css.greenButton}`} onClick={supprimerActivite}>Confirmer</button>
      <button className={`${css.button} ${css.redButton}`} onClick={fermerModalSupprimer}>Annuler</button>
    </div>
        </div>
        </div>
      );
}

InfoActivite.propTypes = {
    fermerModalSupprimer: PropTypes.func.isRequired,
    idDateActivite: PropTypes.number.isRequired,
    activiteSelectionner: PropTypes.func.isRequired,
    idUtilisateur : PropTypes.number.isRequired
  };