import React, { Component } from 'react';

const Authentification = (WrappedComponent, allowedRoles) => {
  return class Authentification extends Component {
    componentDidMount() {
      const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));

      if (!utilisateur || !allowedRoles.includes(utilisateur.idRole)) {
        window.location.replace('/Erreur404'); // Change this to the actual login route in your application
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default Authentification;
