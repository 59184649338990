/*
 Nom du fichier : ActiviteHalteAllaitement.js
 Fonction: ce fichier représente le cote halte allaitement
 Auteur : Thanina adda.
*/
import React, {  useState,useEffect } from 'react'
import './ActiviteHalteAllaitement.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import axios from 'axios'

import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'

function ActiviteHalteAllaitement() {

  const [regions, setRegions] = useState([]);
  const [activites, setActivites] = useState([0]);
  const [activitesDates, setActivitesDates] = useState([0]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);

  useEffect(() => {
    const getRegions = async () => {
        const res = await axios.get('https://api.chat.allaitementquebec.org/region');
        const filteredRegions = res.data.filter(region => region.idTypeActivite === 2 && region.langue === 'Français');
        setRegions(filteredRegions);
    };
    getRegions();

  }, []);

  function handleSelectDate(event) {
    setSelectedDate(event.target.value);

  }

  const handleChangeRegion2 = async (id) => {
    const res = await axios.get(`https://api.chat.allaitementquebec.org/activiteChoisi/${id}`);
    setActivites(res.data);
    setSelectedRegion(id);
    const res2 = await axios.get(`https://api.chat.allaitementquebec.org/activiteChoisiDate/${id}`);
    setActivitesDates(res2.data);
  };

  return (

    <div className="mainActiviteHalte">

      <div className=" col-lg-3">
        <div className="ActiviteGaucheHalte" >
          <br></br>
          <h5 style={{ textAlign: "center" }}>Liste des régions</h5>
          <div className='activiteGHalte'>
            {regions.map(region => (
              <div
                className={`ListeActiviteHalte ${selectedRegion === region.id ? 'selected' : ''}`}
                key={region.id}
                value={region}
                onClick={e => {
                  handleChangeRegion2(region.id);
                  window.innerWidth < 800
                    ? window.scrollBy(0, window.innerHeight)
                    : window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <div className={`col-sm rounded activiteBlock4Halte ${selectedRegion === region.id ? 'selected' : ''}`}>
                  <p>{region.nomRegion}</p>
                </div>
              </div>


            ))}
          </div>

        </div>
      </div>
      <div className="col-lg-9">
        <div className="ActiviteDroiteHalte">
          <br></br>
          <h5 style={{marginLeft:'20px'}}>Haltes allaitement</h5>
          {activites.map((activite) => (

            <div className='activiteDHalte' key={activite.id}>
              <div className="ActiviteUneHalte row">
                <div className="col-sm rounded activiteBlock1Halte" style={{ backgroundColor: '#D0D5E5' }}>
                  <FontAwesomeIcon icon={faPenNib} />
                  <span>  Titre:</span>
                  <p>{activite.nomEvent}</p>
                </div>
                <div className="col-sm rounded activiteBlock1Halte" style={{ backgroundColor: '#D0D5E5' }}>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>  Lieu:</span>
                  <p>{activite.lieu}</p>
                </div>

                <div className="col-sm rounded activiteBlock1Halte" style={{ backgroundColor: '#D0D5E5' }}>
                  <FontAwesomeIcon icon={faDollarSign} />
                  <span>  Prix:</span>
                  <p>{activite.tarif}</p>
                </div>
              </div>
              <div className="ActiviteDeuxHalte row">
                <div className="col-sm rounded activiteBlock2Halte" style={{ backgroundColor: '#D0D5E5' }}>
                  <div className="iconActivite">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <span>  Horaire:</span>
                    <p >{activite.horaire}</p>
                  </div>
                </div>
              </div>

              <div className="ActiviteTroisHalte row">
                <div className="col-sm rounded activiteBlock3Halte" style={{ backgroundColor: '#D0D5E5', textAlign: 'justify' }}>
                  <span>
                    <p>
                      {activite.description}
                    </p>
                  </span>
                </div>

              </div>

              <div className="col-lg-11 single-formActiviteFrancais">
                <div className="rightInscriptionActiviteFrancais">
                  <div className="col-lg-6">
                    <select className="form-select" aria-label=".form-select-sm example" value={selectedDate} onChange={(e) => handleSelectDate(e)}>
                      <option value="">Choisissez la date:</option>
                      {activitesDates.map((activiteDates) => (
                        <option key={activiteDates.id} value={activiteDates.id}>

                          {new Date(activiteDates.date).toLocaleDateString()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
      <div>
        
      </div >
    </div >
  );

}
export default ActiviteHalteAllaitement
