/*
 Nom du fichier : Activite.js
 Fonction: ce fichier représente la page d'inscription aux activités avec la liste des régions
 et le formulaire. 

 Auteur : Thanina adda.
 Modification par Maxence : Envoie du courriel de confirmation d'inscription + changement du fonctionnement du formulaire + 
 ajout de la prise en compte si la personne est connecté et à un compte lors de l'inscription. 
 
*/
import React, { useState, useEffect } from 'react'
import './ActivitePrenatale.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import axios from 'axios'
import DOMPurify from 'dompurify';

import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'


function ActivitePrenatale() {

  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
  const idUtilisateur = utilisateur ? JSON.stringify(utilisateur.id) : null;
  const [regions, setRegions] = useState([]);
  const [activites, setActivites] = useState([0]);
  const [activitesDates, setActivitesDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [lienZoom, setLienZoom] = useState('');
  const [date, setDate] = useState('');
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [courriel, setCourriel] = useState('');
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [horaire, setHoraire] = useState('');
  const [lieu, setLieu] = useState('');
  const [residence, setResidence] = useState('');



  useEffect(() => {
    const getRegions = async () => {
      try {
        const res = await axios.get('https://api.chat.allaitementquebec.org/region');
        const filteredRegions = res.data.filter(region => region.idTypeActivite === 1 && region.langue === 'Français');
        setRegions(filteredRegions);
      } catch (error) {
       
      }
    };
    getRegions();

  }, 
  []);

  function handleSelectDate(e) {
    setSelectedDate(e);
    axios.get(`https://api.chat.allaitementquebec.org/laDate/${e}`).then(res => {
      setDate(res.data[0].date);
      setLienZoom(res.data[0].lienZoom);
    });
  }

  const [showForm, setShowForm] = useState(false)

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  const handleChangeRegion2 = async (id) => {

    const res = await axios.get(`https://api.chat.allaitementquebec.org/activiteChoisi/${id}`);
    setActivites(res.data);
    setHoraire(res.data[0].horaire);
    setLieu(res.data[0].lieu);
    setSelectedRegion(id); // set the selected region ID

    const res2 = await axios.get(`https://api.chat.allaitementquebec.org/activiteChoisiDate/${id}`);
    setActivitesDates(res2.data);

    const response = await axios.get(`https://api.chat.allaitementquebec.org/courrielDeActivite/${res.data[0].id}`);
    setTitre(response.data[0].titre);
    setDescription(response.data[0].description);
  };

  const envoiCourrielInscription = async (e) => {
    e.preventDefault();

    if (idUtilisateur === null) {
      await axios.post(`https://api.chat.allaitementquebec.org/inscriptionUtilisateurActivite`, { nom, prenom, courriel, idDateActivite: selectedDate, residence })
    } else {
      await axios.post(`https://api.chat.allaitementquebec.org/inscriptionUtilisateurActivite`, { nom, prenom, courriel, idUtilisateur, idDateActivite: selectedDate, residence })
    }
    await axios.post('https://api.chat.allaitementquebec.org/courrielActivite', {
      to: courriel,
      subject: titre,
      body: `${description}. Date de l'activité : le ${new Date(date).toLocaleDateString()} situé à ${lieu}. ${lienZoom ? 'Lien Zoom: <a href="' + lienZoom + '">' + lienZoom + '</a>' : ''}`
    });
  
    window.alert('Un email de confirmation vous a été envoyé. Pensez à regarder vos spams', '');
  }


  return (

    <div className="mainActivite">

      <div className=" col-lg-3">
        <div className="ActiviteGauche" >
          <br></br>
          <h5 style={{ textAlign: "center" }}>Liste des régions</h5>
          <div className='activiteG'>
            {regions.map(region => (
              <div
                className={`ListeActivite ${selectedRegion === region.id ? 'selected' : ''}`}
                key={region.id}
                value={region}
                onClick={e => {
                  handleChangeRegion2(region.id);
                  window.innerWidth < 800
                    ? window.scrollBy(0, window.innerHeight)
                    : window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <div className={`col-sm rounded activiteBlock4 ${selectedRegion === region.id ? 'selected' : ''}`}>
                  <p>{region.nomRegion}</p>
                </div>
              </div>


            ))}
          </div>

        </div>
      </div>
      <div className="col-lg-9">
        <div className="ActiviteDroite">
          <br></br>
          <h5 style={{marginLeft:'20px'}}>Activités d'information prénatale</h5>
          {activites.map((activite) => (

            <div className='activiteD' key={activite.id}>
              <div className="ActiviteUne row">
                <div className="col-sm rounded activiteBlock1" style={{ backgroundColor: '#D0D5E5' }}>
                  <FontAwesomeIcon icon={faPenNib} />
                  <span>  Titre:</span>
                  <p>{activite.nomEvent}</p>
                </div>
                <div className="col-sm rounded activiteBlock1" style={{ backgroundColor: '#D0D5E5' }}>
                  <FontAwesomeIcon icon={faLocationDot} />
                  <span>  Lieu:</span>
                  <p>{activite.lieu}</p>
                </div>

                <div className="col-sm rounded activiteBlock1" style={{ backgroundColor: '#D0D5E5' }}>
                  <FontAwesomeIcon icon={faDollarSign} />
                  <span>  Prix:</span>
                  <p>{activite.tarif}</p>
                </div>
              </div>
              <div className="ActiviteDeux row">
                <div className="col-sm rounded activiteBlock2" style={{ backgroundColor: '#D0D5E5' }}>
                  <div className="iconActivite">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <span>  Horaire:</span>
                    <p >{activite.horaire}</p>
                  </div>
                </div>
              </div>

              <div className="ActiviteTrois row">
                <div className="col-sm rounded activiteBlock3" style={{ backgroundColor: '#D0D5E5', textAlign: 'justify' }}>
                  <span>
                    <p>
                      {activite.description}
                    </p>
                  </span>
                </div>

              </div>

              <div className="btnSubmitActivite2">
                <button onClick={toggleForm} type="submit" className="btn btn rounded" style={{ backgroundColor: '#3C4E8B', color: "white" }}>
                  Inscrivez-vous
                </button>
              </div>
            </div>
          ))}

        </div>
        <div>
          {showForm && (
            <div className="col-lg-12">
              <div className="col-lg-12 single-formActivite ">
                <div className="rightInscriptionActivite">
                  <form method="post">
                    <div className="formulaireActiviteUn row" >
                      <div className="col-sm" style={{ paddingBottom: '10px' }}>
                        <input type="text" name="nom" className="input form-control" placeholder="Nom" onChange={(e) => setNom(DOMPurify.sanitize(e.target.value))} required />
                      </div>
                      <div className="col-sm">
                        <input type="text" name="prenom" className="input form-control" placeholder="Prénom" onChange={(e) => setPrenom(DOMPurify.sanitize(e.target.value))} required />
                      </div>
                    </div>
                    <div className="formulaireActiviteDeux row">
                      <div className="col-sm" style={{ paddingBottom: '10px' }}>
                        <input type="text" name="courriel" className="input form-control" placeholder="Courriel" onChange={(e) => setCourriel(DOMPurify.sanitize(e.target.value))} required />
                      </div>
                      <div className="col-sm" >
                        <select className="form-select" aria-label=".form-select-sm example" value={selectedDate} onChange={(e) => handleSelectDate(e.target.value)}>
                          <option value="">Choisissez la date:</option>
                          {activitesDates.map((activiteDates) => (
                            <option key={activiteDates.id} value={activiteDates.id}>

                              {new Date(activiteDates.date).toLocaleDateString()}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="formulaireActiviteTrois row" >
                      <div className="col-sm" >
                        <input type="text" name="residence" className="input form-control" placeholder="Lieu de résidence" onChange={(e) => setResidence(DOMPurify.sanitize(e.target.value))} required />
                      </div>
                    </div>
                    <input type="hidden" name="idDateActivite" value={selectedDate} onChange={handleSelectDate} />
                    <div className="btnSubmitActivite">
                      <button type="submit" className="btn btn-primary rounded " onClick={envoiCourrielInscription}>
                        Inscription
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>

      </div >
    </div >
  );

}
export default ActivitePrenatale
