import React, { useState, useEffect } from 'react';
import '../../scss/style.scss'

import {
  CButton,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CFormTextarea,
  CFormSelect,
  CForm,
  CCol,
  CRow,
  CModal,
  CModalFooter,
  CModalBody,
  CAlert,
  CModalTitle,
  CFormInput,
  CFormLabel,
} from '@coreui/react'

import { AppFooter, AppSidebar } from 'src/components/Administrateur'
import axiosInstance from 'src/hooks/axios';

export default function CourrielActivite() {

  const [courrielActivite, setCourrielActivite] = useState([]);
  const [courrielSansActivite, setCourrielSansActivite] = useState([]);
  const [modalCourriel, setModalCourriel] = useState(false);
  const [modalSupprimer, setModalSupprimer] = useState(false);

  const [alert, setAlert] = useState(false);
  const [alertCreer, setAlertCreer] = useState(false);
  const [apercu, setApercu] = useState(false);
  const [idActiviteSelectionner, setIdActiviteSelectionner] = useState(0);

  const [nouveauSujet, setNouveauSujet] = useState('');
  const [nouvelleObjet, setNouvelleObjet] = useState('');
  const [activiteNouveauCourriel, setActiviteNouveauCourriel] = useState(courrielSansActivite[0]?.id);

  useEffect(() => {
    const fetch = async () => {
      const res = await axiosInstance.get('/courrielActivite');
      const response = await axiosInstance.get('/activiteSansCourriel');
      setCourrielActivite(res.data);
      setCourrielSansActivite(response.data);
      setActiviteNouveauCourriel(response.data[0]?.id);
    }
    fetch();

  }, []);

  const changerTitre = (id, nouveauTitre) => {
    setCourrielActivite(prevCourrielActivite => {
      return prevCourrielActivite.map(courriel => {
        if (courriel.id === id) {
          return { ...courriel, titre: nouveauTitre }
        } else {
          return courriel
        }
      })
    })
  }

  const changerDescription = (id, nouvelleDescription) => {
    setCourrielActivite(prevCourrielActivite => {
      return prevCourrielActivite.map(courriel => {
        if (courriel.id === id) {
          return { ...courriel, description: nouvelleDescription }
        } else {
          return courriel
        }
      })
    })
  }


  const changerCourriel = async (e) => {
    e.preventDefault();

    if (idActiviteSelectionner === null) {
      return;
    }

    const activiteSelectionner = courrielActivite.find((item) => item.id === idActiviteSelectionner);

    if (!idActiviteSelectionner) {
      return;
    }

    const { titre, description } = activiteSelectionner;

    await axiosInstance.put(`/modifierCourrielActivite/${idActiviteSelectionner}`, { titre, description })
    setAlert(true);
  }


  const creerCourriel = async (e) => {
    e.preventDefault();
    await axiosInstance.post(`/creerCourrielActivite`, { titre: nouveauSujet, description: nouvelleObjet, idActivite: activiteNouveauCourriel });
    setAlertCreer(true)
  }

  const supprimer = (id) => {
    axiosInstance.delete(`/supprimerCourrielActivite/${id}`).then(() => { window.location.reload() });
  }

  const alertModifier = async (id) => {
    setAlert(false);
    setIdActiviteSelectionner(id);
  }

  const fermerCreer = async () => {
    setAlertCreer(false);
    setModalCourriel(false);
    window.location.reload();
  }

  const preview = async () => {
    if (apercu === true) {
      setApercu(false);
    } else {
      setApercu(true);
    }
  }
  return (
    <>
      <div>
        <AppSidebar />

        <br />

        <h2>Activités</h2>
        <br />
        <CButton color="success" style={{ marginBottom: '10px' }} onClick={() => setModalCourriel(true)}>Créer</CButton>
        <CAccordion flush>
          {courrielActivite.map(courriel => (
            <CAccordionItem key={courriel.id} onClick={() => alertModifier(courriel.id)}>
              <CAccordionHeader>{courriel.nomEvent} - {courriel.nomRegion}</CAccordionHeader>
              <CAccordionBody>
                <CAlert visible={alert} color="success">Le courriel de l'acitivté a bien été modifié</CAlert>
                <CForm>
                  <CRow className="mb-3">
                    <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Sujet</CFormLabel>
                    <CCol sm={10} >
                      <CFormInput id="inputEmail3" value={courrielActivite.find(email => email.id === courriel.id)?.titre} onChange={(e) => changerTitre(courriel.id, e.target.value)} />
                    </CCol>
                  </CRow>
                  <CRow className="mb-3">
                    <CFormTextarea
                      id="exampleFormControlTextarea1"
                      label="Objet du courriel"
                      rows={3}
                      value={courrielActivite.find(email => email.id === courriel.id)?.description}
                      onChange={(e) => changerDescription(courriel.id, e.target.value)}
                    ></CFormTextarea>

                  </CRow>
                  <CFormLabel>Activité relié</CFormLabel>
                  <CFormSelect aria-label="Default select example">
                    {courrielActivite.map(activite => {
                      let selected = false;
                      if (activite.id === courriel.id) {
                        selected = true;
                      }
                      return (
                        <option key={activite.id} selected={selected}>
                          {activite.nomEvent} - {activite.nomRegion}
                        </option>
                      )
                    })}
                  </CFormSelect>
                  <br />
                  {apercu === true ?
                    <div>
                      <p style={{ fontWeight: 'bold' }}>L'aperçu est juste à type indicatif. De plus, la date, l'heure, le lieu et le lien zoom dépendra de l'activité auquelle le parent s'inscrit.</p>
                      <p>Sujet : {courrielActivite.find(email => email.id === courriel.id)?.titre}</p>
                      <p>Objet : {courrielActivite.find(email => email.id === courriel.id)?.description} Date de l'activité : le 02/04/2023 à 19h à 21h, situé à a distance. Lien Zoom: Lien</p>
                      <br></br>
                      <img alt="" src="https://ci4.googleusercontent.com/proxy/Zym45X7v2x3BKsjplfDl90IpbMcK0cSrA79nlk5JMQ1G-E_CPRu2fh1xrY3nGyBzuRbCfekTv3SMGyc2jCAapYQHD8Wc2RJR4PbWJXoKb2AEHMBEnlM=s0-d-e1-ft#https://allaitementquebec.org/wp-content/uploads/logo-signature.png"></img>

                      <p>Allaitement Québec | Groupe d'entraide en allaitement de la Capitale-Nationale et Chaudière-Appalaches</p>
                      <p>Bureau : 418-704-3575</p>
                      <p>allaitementquebec.org</p>
                      <p>https://www.facebook.com/AllaitementQuebec</p>
                    </div>
                    : ""}
                  <CButton color="warning" style={{ marginRight: '10px' }} onClick={preview}>Voir un aperçu</CButton>
                  <CButton type="submit" onClick={(e) => changerCourriel(e)}>Modifier</CButton>
                  <CButton color="danger" style={{ marginLeft: '10px' }} onClick={() => setModalSupprimer(true)}>Supprimer</CButton>
                </CForm>
              </CAccordionBody>
            </CAccordionItem>
          ))}

        </CAccordion>
        <br />

        <CModal visible={modalCourriel}>
          <CModalTitle style={{ padding: '10px' }}>Créer un nouveau courriel</CModalTitle>
          <CModalBody>
            <CFormLabel>Sujet</CFormLabel>
            <CFormInput
              onChange={(e) => setNouveauSujet(e.target.value)}
              required
            ></CFormInput>
            <CFormLabel>Objet </CFormLabel>
            <CFormTextarea
              onChange={(e) => setNouvelleObjet(e.target.value)}
              required>

            </CFormTextarea>
            <br />
            <CFormLabel>Activité sans email</CFormLabel>
            <CFormSelect aria-label="Default select example" onChange={(e) => setActiviteNouveauCourriel(e.target.value)}>
              {courrielSansActivite.map(activite => {
                return (
                  <option key={activite.id} value={activite.id}>
                    {activite.nomEvent} - {activite.nomRegion}
                  </option>
                )
              })}

            </CFormSelect>
            <br></br>
            <CAlert visible={alertCreer} color="success">Le courriel de l'activité a été créer</CAlert>
          </CModalBody>
          <CModalFooter>
            <CButton onClick={fermerCreer}>
              Fermer
            </CButton>
            <CButton
              color="success"
              onClick={creerCourriel}
            >
              Créer
            </CButton>
          </CModalFooter>
        </CModal>

        <CModal visible={modalSupprimer}>
          <CModalTitle style={{ padding: '10px' }}>Supprimer le courriel</CModalTitle>
          <CModalBody>Êtes-vous vraiment sûr de vouloir supprimer ?</CModalBody>
          <CModalFooter>
            <CButton
              onClick={() => setModalSupprimer(false)}
            >
              Fermer
            </CButton>
            <CButton
              color="danger"
              onClick={() =>
                supprimer(
                  idActiviteSelectionner
                )
              }
            >
              Supprimer
            </CButton>
          </CModalFooter>
        </CModal>

        <div>
          <AppFooter />
        </div>

      </div>


    </>
  )
}