/*
 Nom du fichier : Navbar.js
 Fonction: ce fichier représente le css du fichier Navbar.js, avec le toggle down, ainsi que le mode humberger.

 Auteur : Thanina adda.
 Modification: Maxence Mazeau a ajouté le module Déconnexion ainsi que les liens une fois connecté.

 Modification2: Thanina Adda a modifié la strucutre des liens pour une version finale.
*/


import React, { useState } from 'react'
import './Navbar.css'


function toggleNavbar() {
  var navbar = document.getElementById('navbarNav')
  if (navbar.classList.contains('show')) {
    navbar.classList.remove('show')
  } else {
    navbar.classList.add('show')
  }
}


function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [isOpen3, setIsOpen3] = useState(false)
  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'))

  function MonLienDeConnexion() {
    window.location.href = '/Connexion'
  }
  function MonLienDeActivite() {
    window.location.href = '/ActivitePrenatale'
  }

  function MonLienDeActivite2() {
    window.location.href = '/ActiviteHalteAllaitement'
  }
  function MonLienDeActivite3() {
    window.location.href = '/ActiviteHalteAllaitementAnglais'
  }
  function MonLienInscriptionParent() {
    window.location.href = '/InscriptionParent'
  }
  function MonLienInscriptionBenevole() {
    window.location.href = '/InscriptionBenevole'
  }
  function InformationParentParDefaut() {
    window.location.href = '/InformationParent'
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  const handleClick3 = () => {
    setIsOpen3(!isOpen3)
  }
  // permet d'afficher le menu deroulant pour activites des que la souris se pose dessus
  const handleMouseEnter3 = () => {
    setIsOpen3(true);
  };

  const handleMouseLeave3 = () => {
    setIsOpen3(false);
  };

 // permet d'afficher le menu deroulant pour l'inscription des que la souris se pose dessus
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };


 // permet d'afficher le menu deroulant pour de mon profil des que la souris se pose dessus
  const handleMouseEnter2 = () => {
    setIsOpen2(true);
  };

  const handleMouseLeave2 = () => {
    setIsOpen2(false);
  };
  const handleClick2 = () => {
    setIsOpen2(!isOpen2)
  }

  const Informations = () => {
    if (utilisateur.idRole === 1) {
      window.location.href = '/Profile'
    } else {
      window.location.href = '/ProfilBenevole'
    }
  }

  const TableauDeBord = () => {
    if (utilisateur.idRole === 1) {
      window.location.href = '/Chat'
    } else {
      window.location.href = '/TableauDeBordChatBenevole'
    }
  }

  const DocumentationsUtilisateur = () => {
    if (utilisateur.idRole === 1) {
      window.location.href = '/InformationParent'
    } if (utilisateur.idRole === 2) {
      window.location.href = '/InformationBenevole'
    }

  }


  const Deconnexion = async () => {
    await sessionStorage.clear();
    window.location.href = '/Connexion'
  }

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-xl navbar-light " style={{ backgroundColor: 'rgba(61, 72, 139)', padding: '1%' }}>
        <div className="container-fluid">
          <a href="https://allaitementquebec.org/">
            <img src="./assets/img/allaitement/home.png" className="home" style={{ width: '18px' }} />
          </a>
          <div className="vr" style={{ color: 'white', marginLeft: '10px' }} />
          <img src="./assets/img/allaitement/Logo.png" className="logoimage2" alt="Logo" style={{ width: '40px' }} />

          <button onClick={toggleNavbar} className="navbar-toggler" type="button" style={{ textDecoration: 'none', outline: 'none', boxShadow: 'none' }}>
            <span >
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none" style={{ textDecoration: 'none', outline: 'none', boxShadow: 'none' }}>
                <path stroke="white" strokeWidth={'3'} d="M4 7h22M4 15h22M4 23h22" />
              </svg>
            </span>

          </button>

          <div className="collapse navbar-collapse justify-content-between" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="https://allaitementquebec.org/a-propos/" style={{ color: 'white' }}>
                  À propos
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://allaitementquebec.org/services/" style={{ color: 'white' }}>
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://allaitementquebec.org/contact/" style={{ color: 'white' }}>
                  Contact
                </a>
              </li>
              <div className="nav-item dropdown" onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3}>
                <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleClick3} style={{ color: 'white' }}>
                  Activités
                </a>
                <ul className={`dropdown-menu ${isOpen3 ? 'show' : ''}`} aria-labelledby="navbarScrollingDropdown">
                  <li className="nav-item">
                    <a className="nav-link" onClick={MonLienDeActivite} style={{ color: 'black' }} >
                      Prénatales
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={MonLienDeActivite2} style={{ color: 'black' }} >
                      Haltes allaitement
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={MonLienDeActivite3} style={{ color: 'black' }} >
                      Breastfeeding stop
                    </a>
                  </li>
                </ul>
              </div>
              {utilisateur ? (
                <div className="nav-item dropdown" onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
                  <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleClick2} style={{ color: 'white' }}>
                    Mon profil
                  </a>
                  <ul className={`dropdown-menu ${isOpen2 ? 'show' : ''}`} aria-labelledby="navbarScrollingDropdown">
                    <li className="nav-item">
                      <a className="nav-link" onClick={Informations} style={{ color: 'black' }}>
                        Mes informations
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    {utilisateur.idRole === 1 ?  
                    <li className="nav-item">
                      <a className="nav-link" onClick={TableauDeBord} style={{ color: 'black' }}>
                        Activités et discussions
                      </a>
                    </li> : 
                    <li className="nav-item">
                      <a className="nav-link" onClick={TableauDeBord} style={{ color: 'black' }}>
                        Clavardage
                      </a>
                    </li>
                    }
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" onClick={DocumentationsUtilisateur} style={{ color: 'black' }}>
                        Documentation
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" onClick={Deconnexion} style={{ color: 'black' }}>
                        Déconnexion
                      </a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className="nav-item dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleClick} style={{ color: 'white' }}>
                    Connexion/Inscription
                  </a>
                  <ul
      className={`dropdown-menu ${isOpen ? 'show' : ''}`}
      aria-labelledby="navbarScrollingDropdown">
      <li className="nav-item">
        <a className="nav-link" onClick={MonLienInscriptionParent} style={{ color: 'black' }}>
          Inscription parent
        </a>
      </li>

      <li>
        <hr className="dropdown-divider" />
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={MonLienInscriptionBenevole} style={{ color: 'black' }}>
          Devenez bénévole
        </a>
        <hr className="dropdown-divider" />
      </li>
      <li className="nav-item">
        <a className="nav-link" onClick={MonLienDeConnexion} style={{ color: 'black' }}>
          Se connecter
        </a>
      </li>
    </ul>
                </div>
              )}

            </ul>

            <img src="./assets/img/allaitement/Logo.png" className="logoimage" alt="Logo" style={{ width: '3%', position: 'center' }} />

            <ul className="navbar-nav ">
              <li className="nav-item">
                <a className="nav-link" onClick={InformationParentParDefaut} style={{ color: 'white' }}>
                  Documentation
                </a>
              </li>
              <div className="vr" style={{ color: 'white' }}></div>
              <li className="nav-item">
                <a className="nav-link" href="https://allaitementquebec.org/commentaire/" style={{ color: 'white' }}>
                  Laissez un commentaire
                </a>
              </li>

              <div className="vr" style={{ color: 'white', marginRight: '10px' }}></div>

              <li className="nav-item">
                <a className="nav-link" href="" style={{ color: 'white' }}>
                  Appelez-nous: 418-704-3575
                </a>
              </li>

              <div className="vr" style={{ color: 'white' }}></div>
              <li>
                <a href="https://www.facebook.com/AllaitementQuebec/">
                  <img src="./assets/img/allaitement/facebook.png" alt="Lien vers la page Facebook" className="facebook" style={{ width: '20px', height: '20px', top: '6px' }}></img>
                </a>
              </li>

            </ul>
          </div>
        </div>
      </nav >
    </React.Fragment >
  )
}

export default Navbar
