/*
 nom du fichier : chat.js

 fonction: ce fichier représente la page d'inscription du bénévole, avec le formulaire ainsi que les textes.

 auteur : Thanina adda.
 Modification: Thanina Adda, suite au retour de la cliente, j'ai apporté les modifications dans le html et css

*/

//la liste des imports.
import './InscriptionBenevole.css'
import imageBebe from './images/image1.jpg'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import DOMPurify from 'dompurify';

//Inscription Bénévole.
function InscriptionBenevole() {

  // Permet de récupérer le texte pour la page Inscription Bénévole.
  const [pages, setPages] = useState([]);
  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [erreur, setErreur] = useState('');
  const [erreurMDP, setErreurMDP] = useState('');
  const [benevole, setBenevole] = useState({
    nom: "",
    prenom: "",
    courriel: "",
    telephone: "",
    motDePasse: "",
    dateNaissance: "",
    langue: "",
    residence: "",
    dureeTotalAllaitement: "",
    experience: "",
    commentaire: ""
  });

  useEffect(() => {
    const getPages = async () => {
      const res = await axios.get(`https://api.chat.allaitementquebec.org/pagesInterfaces/3`);
      setPages(res.data);

      const response = await axios.get(`https://api.chat.allaitementquebec.org/courrielInscription`);
      setTitre(response.data[0].titre);
      setDescription(response.data[0].description);
    };
    getPages();
  }, []);

  useEffect(() => {
    const motDePasseInput = document.getElementById('motDePasse');

    motDePasseInput.addEventListener('input', function () {
      if (motDePasseInput.validity.tooShort) {
        setErreurMDP(true)
      } else {
        setErreurMDP(false);
      }
    });
  }, []);

  async function hashPassword(password) {
    const encoder = new TextEncoder();
    const data = encoder.encode(password);
    const buffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(buffer));
    const hash = hashArray.map(byte => byte.toString(16).padStart(2, "0")).join("");
    return hash;
  }

  const envoiConfirmationInscription = async (e) => {
    e.preventDefault();
    if (benevole.motDePasse.length > 6) {
      const hashedPassword = await hashPassword(benevole.motDePasse);

      await axios.post(`https://api.chat.allaitementquebec.org/creerBenevole`, {
        dateNaissance: benevole.dateNaissance,
        nom: benevole.nom,
        prenom: benevole.prenom,
        courriel: benevole.courriel,
        motDePasse: hashedPassword,
        telephone: benevole.telephone,
        langue: benevole.langue,
        dureeTotalAllaitement: benevole.dureeTotalAllaitement,
        experience: benevole.experience,
        commentaire: benevole.commentaire,
        residence: benevole.residence
      }).then(res => {
        if (res.data.emailExists === false) {
          axios.post('https://api.chat.allaitementquebec.org/inscription', {
            to: benevole.courriel,
            subject: titre,
            body: description
          });
          setErreur("");
          alert(`Une confirmation d'inscription a été envoyer. Regardez vous spams`);

          window.location.href = '/Connexion';
        } else {
          setErreur("Le courriel existe déjà");
        }
      });
    } else {
      setErreurMDP("Le mot de passe doit faire 6 caractères minimum");
    }
  }

  return (
    <div className="mainInscriptionBenevole">
      <div className="form-areaInscriptionBenevole row col-12 ">
        <div className="col-12">
          <h3 className='titreInterfaceBenevole'>
            <FontAwesomeIcon icon={faCaretLeft} />
            Devenez bénévole
          </h3>
          <div className="row g-0 bg-transparent d-flex ">
            <div className="col-lg-5 single-form ">
              <div className="rightInscriptionBenevole">
                <form method="post">
                  <p style={{ color: 'red' }}>{erreur}</p>
                  <p>Les champs avec un * sont obligatoires</p>
                  <div className="row g-0" style={{ paddingBottom: '10px' }}>
                    <div className="col" style={{ marginRight: '5px' }}>
                      <input type="text" name="nom" className="input form-control" placeholder="Nom *" onChange={(e) =>
                        setBenevole({ ...benevole, nom: DOMPurify.sanitize(e.target.value) })
                      } required />
                    </div>

                    <div className="col" style={{ marginLeft: '5px' }}>
                      <input type="text" name="prenom" className="input form-control" placeholder="Prénom *" onChange={(e) =>
                        setBenevole({ ...benevole, prenom: DOMPurify.sanitize(e.target.value) })
                      } required />
                    </div>
                  </div>
                  <div className="row g-0" style={{ paddingBottom: '10px' }}>
                    <div className="col" style={{ marginRight: '5px' }}>
                      <input type="email" name="courriel" className="input form-control" placeholder="Courriel *" onChange={(e) =>
                        setBenevole({ ...benevole, courriel: DOMPurify.sanitize(e.target.value) })
                      } required />
                    </div>

                    <div className="col" style={{ marginLeft: '5px' }}>
                      <input
                        type="phone"
                        name="telephone"
                        className="input form-control"
                        placeholder="Téléphone *"
                        onChange={(e) => {
                          const phoneNumber = DOMPurify.sanitize(e.target.value);
                          const trimmedPhoneNumber = phoneNumber.slice(0, 10); // Récupère les 10 premiers caractères
                          setBenevole({ ...benevole, telephone: trimmedPhoneNumber });}} required maxLength={10}/>


                    </div>
                  </div>

                  <div className="mb-3">
                    <input type="password" name="motDePasse" className="input form-control" placeholder="Mot de passe *" onChange={(e) =>
                      setBenevole({ ...benevole, motDePasse: DOMPurify.sanitize(e.target.value) })
                    } required />
                    <p id="motDePasse" style={{ color: 'red' }}>{erreurMDP}</p>
                  </div>
                  <div className="mb-3">
                    <label>* Date de naissance :</label>
                    <input type="date" name="dateNaissance" className="input form-control" placeholder="Date de naissance *" onChange={(e) =>
                      setBenevole({ ...benevole, dateNaissance: DOMPurify.sanitize(e.target.value) })
                    } required />
                  </div>
                  <div className="mb-3">
                    <input type="text" name="langue" className="input form-control" placeholder="Langue *" onChange={(e) =>
                      setBenevole({ ...benevole, langue: DOMPurify.sanitize(e.target.value) })
                    } required />
                  </div>
                  <div className="mb-3">
                    <input type="text" name="residence" className="input form-control" placeholder="Lieu de résidence *" onChange={(e) =>
                      setBenevole({ ...benevole, residence: DOMPurify.sanitize(e.target.value) })
                    } required />
                  </div>
                  <div className="mb-3">
                    <input type="text" name="dureeTotalAllaitement" className="input form-control" placeholder="Durée totale d'allaitement (En Mois) *" onChange={(e) =>
                      setBenevole({ ...benevole, dureeTotalAllaitement: DOMPurify.sanitize(e.target.value) })
                    } required />
                  </div>
                  <div className="mb-3">
                    <textarea className="form-control" name="experience" id="exampleFormControlTextarea1" placeholder="Expérience(s) bénévole(s) passée(s) *" rows="3" onChange={(e) =>
                      setBenevole({ ...benevole, experience: DOMPurify.sanitize(e.target.value) })
                    } required></textarea>
                  </div>
                  <div className="mb-3">
                    <textarea className="form-control" name="commentaire" id="exampleFormControlTextarea1" placeholder="Commentaire *" rows="3" onChange={(e) =>
                      setBenevole({ ...benevole, commentaire: DOMPurify.sanitize(e.target.value) })
                    } required></textarea>
                  </div>
                  <div className="btnSubmit">
                    <button type="submit" className="btn btn-primary rounded" onClick={envoiConfirmationInscription}>
                      Inscription
                    </button>
                  </div>
                </form>
                <div className="spanFomulaireBenevole">
                  <span>Inscrivez-vous pour devenir un bénévole.</span>
                </div>
              </div>
            </div>
            <div className=" col-lg-5 ">
              <div className="leftInscriptionBenevole">
                {pages.map(page =>
                  <React.Fragment key={page.id}>
                    <h3>{page.titre}</h3>
                    <div className="texteLeftInscriptionBenevole">
                      <div>
                        <span className="spanTexte">
                          {page.description}

                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InscriptionBenevole