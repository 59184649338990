
/*
 nom du fichier : chat.jsx
 fonction: ce fichier represente le chat en lui-meme, avec les messages contenu dans le chat( clavardage). 
            on peut voir le cote benevole et parent. 

 auteur : Thanina adda.
 modification apportées par Maxence MAZEAU: Passage de Class à function, Ajout de l'affichage des discussions en fonction du parent selectionner 
 et de tous ses anciens chats. Ajout des messages en temps réels, gestions des status de chat. Ajout de la fonction de chargement
 des messages précédents. CSS dela page chat bénévole. Affichage des informations des messages et des chats dans la liste chat. Notifications,
 compteur de notification, reception des images de la bd et live

*/

import React, { useState, useEffect, useRef } from "react";
import axiosInstance from 'src/hooks/axios';
import io from "socket.io-client";
import css from '../../ChatParent/Chat.module.css';
import son from 'src/components/son/sonNotification.mp3'
import DOMPurify from 'dompurify';
import InfoParent from './InfoParent';



const socket = io.connect("https://api.chat.allaitementquebec.org/");

export default function Chat() {
  const utilisateur = JSON.parse(sessionStorage.getItem('utilisateur'));
  const idRole = utilisateur.idRole;
  if (idRole !== 2) {
    window.location.href = '/Error404';
  }


  const [discussion, setDiscussion] = useState([]);

  const [chat, setChat] = useState([]);
  const [chatSelectionner, setChatSelectionner] = useState("");
  const [chatActuel, setChatActuel] = useState();
  const input = useRef(null);

  const [idUtilisateurParent, setIdUtilisateurParent] = useState('');

  const benevoleSession = JSON.parse(sessionStorage.getItem('utilisateur'));
  const idUtilisateur = JSON.stringify(benevoleSession.id);

  const [messageCount, setMessageCount] = useState(15);
  const [compteurNotification, setCompteurNotification] = useState({});

  const [selectedStatut, setSelectedStatut] = useState('');

  const [toggle, setToggle] = useState(false);
  const [modalOuvert, setModalOuvert] = useState(false);

  let emetteur = 'benevole';

  const utilisateurSocket = idUtilisateur;
  const [messageSocket, setMessageSocket] = useState([]);

  const [statut, setStatut] = useState([]);

  let filtreStatut = 1;

  const sonNotification = useRef(null);

  useEffect(() => {
    axiosInstance.get(`/obtenirChat/${filtreStatut}`).then(res => {
      setChat(res.data);
    });

    axiosInstance.get(`/obtenirStatut`).then(res => {
      setStatut(res.data);
    });
  }, []);
  //Récupère les informations au chargement, recharge à la modification de certains états permet réception des messages lives, notifications et images
  useEffect(() => {
    socket.on("recoitMessage", (data) => {

      setMessageSocket((prevMessageSocket) => [
        ...prevMessageSocket,
        {
          ...data,
          image: data.image ? new File([data.image], data.imageName) : null,
        },
      ]);
      if (data.idChat !== chatActuel) {
        setCompteurNotification((prevCompteurNotification) => ({
          ...prevCompteurNotification,
          [data.idChat]: (prevCompteurNotification[data.idChat] || 0) + 1,
        }));
        sonNotification.current.play();
      }
    });
    return () => {
      socket.off("recoitMessage");
    };

  }, [chatActuel, compteurNotification]);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.key === 'Enter') {
        envoieMessage();
      }
    }
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const choisirFiltreStatut = async (id) => {
    filtreStatut = id;
    setSelectedStatut(id);
    await axiosInstance.get(`/obtenirChat/${filtreStatut}`).then(res => setChat(res.data));

  }

  //Selectionne le chat voulu
  const selectionChat = async (id, idUtilisateur) => {
    setChatSelectionner(id);
    setChatActuel(id);
    setIdUtilisateurParent(idUtilisateur);


    socket.emit("regoinChat", id);

    socket.emit("nouveauUtilisateur", utilisateurSocket);

    setCompteurNotification({ ...compteurNotification, [id]: 0 });

    const res = await axiosInstance.get(
      `/discussionHistorique/${idUtilisateur}?limit=${messageCount}`
    );

    //Récupère l'image et la transforme en url blob
    const messagesImage = res.data.map(async (message) => {
      if (message.image) {
        const blob = new Blob([new Uint8Array(message.image.data)], { type: message.image.type });
        const url = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = function (event) {
            const url = event.target.result;
            resolve(url);
          };
          reader.readAsDataURL(blob);
        });
        return { ...message, image: url };
      } else {
        return message;
      }
    });

    const tableauMessage = await Promise.all(messagesImage);
    setDiscussion(tableauMessage.reverse());
  }

  const ouvrirMenuFiltre = () => {
    setToggle((prevToggle) => !prevToggle);
  }

  const changerFiltreStatut = (id) => {
    setToggle(false);
    filtreStatut = id
    const idChat = chatSelectionner;
    axiosInstance.put(`/changerStatutChat/${idChat}`, { idStatut: filtreStatut });
    alert('Le clavardage a bien été deplacé');
  }

  const envoieMessage = async (e) => {
    e.preventDefault();
    const messageEnvoie = input.current.value;
    const message = DOMPurify.sanitize(messageEnvoie);

    const idChat = chatSelectionner;
    await axiosInstance.post(`/envoieMessageBenevole`, { message, idChat, idUtilisateur });

    setMessageSocket([...messageSocket, { message, emetteur, idChat }]);
    socket.emit("envoieMessage", {
      message,
      idChat: idChat,
      emetteur,
    });

    input.current.value = "";

  };

  const chargerMessage = async () => {
    const res = await axiosInstance.get(
      `/discussionHistorique/${idUtilisateurParent}?limit=${messageCount + 15}`
    );
    const olderMessages = res.data.reverse();
    setDiscussion([...olderMessages, ...discussion]);
    setMessageCount(messageCount + 15);

  };

  const ouvrirModal = () => {
    setModalOuvert(true);
  }

  const fermerModal = () => {
    setModalOuvert(false);
  }

  return (

    <div className={css.chatContainer}>
      <div className={css.sidebar}>
        <h2>Les chats</h2>
        <div className={css.row}>
          {statut.map(statuts => (
            <p className={`${css.column} ${statuts.id === selectedStatut ? css.selected : ''}`}
              key={statuts.id}
              onClick={e => choisirFiltreStatut(statuts.id)}
              tabIndex="1">
              {statuts.statut}
            </p>
          ))}
        </div>

        {chat.map(chats => (
          <div
            key={chats.id}
            className={`${css.info} ${chats.id === chatSelectionner ? css.selected : ''}`}
            onClick={e => selectionChat(chats.id, chats.idUtilisateur)}
            tabIndex="0"
          >
            {compteurNotification[chats.id] > 0 && (
              <span className={css.compteur}>{compteurNotification[chats.id]}</span>
            )}
            <h4 className={css.nom}>
              {chats.prenom} {chats.nom}
            </h4>
            <p className={css.description}>{chats.message}</p>
            <p className={css.parentInfo} onClick={ouvrirModal}>
              Plus d'informations
            </p>
            <p className={css.temps}>{new Date(chats.dateDebut).toLocaleString()}</p>
          </div>
        ))}
        {modalOuvert && <InfoParent fermerModal={fermerModal} idUtilisateurParent={idUtilisateurParent} />}
      </div>
      <div className={css.fenetreChat}>

        {messageCount <= discussion.length ? (
          <button className={css.loadMoreButton} onClick={chargerMessage}>
            Charger plus de messages
          </button>
        ) : null}

        {discussion
          .map((messages) => (
            <div
              className={`${css.message} ${String(messages.idUtilisateur) !== idUtilisateur ? css.received : css.sent
                }`}
              key={messages.id}
            >
              <div className={css.messageInfo}>
                {messages.image && (
                  <img className={css.image} src={messages.image} alt="Sent image" />
                )}
                {!messages.image && <p>{messages.message}</p>}
                <p className={css.messageTime}>{new Date(messages.moment).toLocaleString()}</p>
              </div>
            </div>
          ))}

        {messageSocket
          .filter((msg) => chatSelectionner === msg.idChat)
          .map((messages, index) => (
            <div
              className={`${css.message} ${messages.emetteur === "parent" ? css.received : css.sent
                }`}
              key={index}
            >
              <div className={css.messageInfo}>
                {messages.image ? (
                  <img
                    className={css.image}
                    src={URL.createObjectURL(messages.image)}
                    alt="Received image"
                  />
                ) : (
                  <p>{messages.message}</p>
                )}
                <p className={css.messageTime}>{new Date().toLocaleString()}</p>
              </div>
            </div>
          ))}

        <form className={css.newMessageForm}>
          <input
            type="text"
            placeholder="Ecrire un message..."
            ref={input}
          />
          <button type="button" onClick={ouvrirMenuFiltre}>Statuts</button>
          {toggle ? (
            <ul className={css.menuFiltre}>
              {statut.map((statuts) => (
                <li className={css.menuFiltreItem} key={statuts.id}>
                  <button onClick={e => changerFiltreStatut(statuts.id)}>{statuts.statut}</button>
                </li>
              ))}
            </ul>
          ) : null}
          <button type="submit" onClick={envoieMessage}>Envoyer</button>
        </form>

      </div>

      <audio ref={sonNotification} src={son} />
    </div>


  );
}

