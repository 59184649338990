import React, { useState, useEffect } from 'react';
import axiosInstance from 'src/hooks/axios';
import PropTypes from 'prop-types';
import {
    CFormSelect,
    CFormLabel,
    CTableBody,
    CTable,
    CTableHead,
    CTableHeaderCell,
    CTableDataCell,
    CTableRow
  } from '@coreui/react'

export default function DateActivite({idActiviteSelectionner}){

    const [dateActivite, setDateActivite] = useState([]);
    const [parentInscrit, setParentInscrit] = useState([]);

    useEffect(() => {
        if (idActiviteSelectionner) {
          const fetch = async() => {
            const res = await axiosInstance.get(`/dateActivite/${idActiviteSelectionner}`);
            setDateActivite(res.data);  
          } 
          fetch();
        }
      }, [idActiviteSelectionner]);


      const dateSelectionner = async(id) => {
        const res = await axiosInstance.get(`/listeInscrit/${id}`);
        setParentInscrit(res.data);  
      }

    return (

        <div>  
        <CFormLabel>Dates et lien zoom de l'activité</CFormLabel>
            <CFormSelect aria-label="Default select example" onChange={(e) => dateSelectionner(e.target.value)}>
            <option defaultValue="">
                     --Sélectionnez une date--
                    </option>
                {dateActivite.map(dates => {
                     
                    return (
                        <option  key={dates.id} value={dates.id}>
                            {new Date(dates.date).toLocaleDateString()} - {dates.lienZoom}
                        </option>
                        )
                        })}
            </CFormSelect>
            <br></br>     
            <CTable>
  <CTableHead>
    <CTableRow color="secondary">
      <CTableHeaderCell scope="col">Nom</CTableHeaderCell>
      <CTableHeaderCell scope="col">Prénom</CTableHeaderCell>
      <CTableHeaderCell scope="col">Courriel</CTableHeaderCell>
      <CTableHeaderCell scope="col">Résidence</CTableHeaderCell>
    </CTableRow>
  </CTableHead>
  <CTableBody>
    {parentInscrit.map(parent => (
    <CTableRow key={parent.id}>
      <CTableHeaderCell scope="row">{parent.nom}</CTableHeaderCell>
      <CTableDataCell>{parent.prenom}</CTableDataCell>
      <CTableDataCell>{parent.courriel}</CTableDataCell>
      <CTableDataCell>{parent.residence}</CTableDataCell>
    </CTableRow>
    ))}
  </CTableBody>       
 </CTable>     
</div>
    )
}

DateActivite.propTypes = {
    idActiviteSelectionner: PropTypes.number.isRequired,
}