import React, { useState, useEffect } from 'react';
import '../../scss/style.scss'

import {
  CButton,
  CFormTextarea,
  CForm,
  CCol,
  CRow,
  CAlert,
  CFormInput,
  CFormLabel,
} from '@coreui/react'
import { AppSidebar } from 'src/components/Administrateur'
import axios from 'axios';
import axiosInstance from 'src/hooks/axios';

export default function CourrielMotDePasse(){

const [titre, setTitre] = useState('');
const [description, setDescription] = useState('');
const [alert, setAlert] = useState(false);

  useEffect(() => {
      const fetch = async() => {
        const res =  await axios.get('https://api.chat.allaitementquebec.org/courrielMotDePasse');
        setTitre(res.data[0].titre);
        setDescription(res.data[0].description);    
      }

      fetch();

  }, []);

  const changerCourriel = (e) => {
    e.preventDefault();
    axiosInstance.put('/modifierCourrielMotDePasse', {titre, description});
    setAlert(true);
  }
    return(
      <>
        <div>
          <AppSidebar />
            
          <br/>    
            <h2>Mot de passe</h2>
          <br/>
          <CAlert visible={alert} color="success">Le courriel de mot de passe a bien été modifié</CAlert> 
          <CForm>
                <CForm>
                    <CRow className="mb-3">
                        <CFormLabel htmlFor="inputEmail3" className="col-sm-2 col-form-label">Sujet</CFormLabel>
                            <CCol sm={10} >
                                <CFormInput type="text" id="inputEmail3" value={titre}
                                    onChange={(e) => setTitre(e.target.value)}
                                />
                            </CCol>
                        </CRow>
                    <CRow className="mb-3">
                        <CFormTextarea
                            id="exampleFormControlTextarea1"
                            label="Objet du courriel"
                            rows={3}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></CFormTextarea>
                    </CRow>
            <br />
                    <CButton type="submit" onClick={changerCourriel}>Modifier</CButton>
                </CForm>
            </CForm>

      </div>
            
       
        </>
    )
}